import React, { FC, forwardRef } from 'react'
import {
  Box,
  Carousel,
  ResponsiveImage,
  Heading,
  Columns,
  BoxLink,
  Text,
  Grid,
  LinkedCard,
} from '../../design-system'
import { LoadingOverlay } from '../../design-system/atoms/loading-spinner'
import { SubpageGridProps } from '../../twt-core/organisms/subpage-grid'
import { ArrowRight, ArrowSmall } from '../../twt-core/graphics'
import { useTheme } from '../../design-system/theme';
import { ButtonAccessory } from '../../design-system/atoms/button-accessory';
import { Debug } from '../../cms/cms.util';

export const DigitalStalls2020: React.FC<SubpageGridProps> = forwardRef(({ items, loading, ...props }, ref) => {
  const theme = useTheme()

  return (
    <Grid ref={ref} width="100%" columnSize={320} maxColumnSize={500} gap={2} alignItems='start' justifyItems='start' {...props}>
      <LoadingOverlay loading={loading} />
      {items?.map((s) => (
        <LinkedCard
          paddingVertical={1}
          paddingHorizontal={1}
          spacing={2}
          justifyContent="flex-start"
          to={s.url}
          className="programme-cell"
          maxWidth={"100%"}
          width="100%"
          variant="stall"
        >
          <ResponsiveImage
            height={{ mobile: '194px', tablet: '194px' }}
            image={s.image || require('../../twt-core/graphics/crowdlarge.jpg')}
          />

          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Text>{s.title}</Text>
            <span style={{ marginLeft: 'auto' }}>
              <ArrowSmall stroke={theme.colors.indigo} />
            </span>
          </div>
        </LinkedCard>
      ))}
    </Grid>
  )
})
