import { css } from '@emotion/core'
import { format, getMonth, isAfter, isBefore, isSameDay } from 'date-fns'
import { motion } from 'framer-motion'
import { graphql } from 'gatsby'
import { debounce } from 'lodash'
import qs from 'query-string'
import * as React from 'react'
import useArrayState from 'use-array-state'
import { useCmsPageContext } from '../../cms'
import {
  BlockComponent, getStructFieldOptional, isBlockType
} from '../../cms/cms.util'
import { CmsPageData_children_WAGTAIL_HomePage } from '../../cms/__generated__/CmsPageData'
import { Box, Columns, styled, Text } from '../../design-system'
import { Rows } from '../../design-system/atoms/flex'
import { useTheme } from '../../design-system/theme'
import { Calendar } from '../../twt-2020/molecules/calendar'
import {
  Button,
  ChipGrid, useWindowOnMobile, WindowOnMobile
} from '../../twt-core'
import { formatFormation, hackFixStupidDjangoDate } from '../../util'
import { EventCard } from '../molecules/event-card'
import {
  EventListBlock, EventListBlock_blocks_WAGTAIL_PageChooserBlock, EventListBlock_blocks_WAGTAIL_PageChooserBlock_page_WAGTAIL_HomePage_events
} from './__generated__/EventListBlock'

type Event = EventListBlock_blocks_WAGTAIL_PageChooserBlock_page_WAGTAIL_HomePage_events

const EmptyButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
  width: 100%;
`

const isFuture = (event: Event) => {
  if (!event.startTime) return false
  const startTime = hackFixStupidDjangoDate(event.startTime)
  const now = hackFixStupidDjangoDate()
  return isAfter(startTime, now)
}

const isPast = (event: Event) => {
  if (!event.endTime) return false
  const endTime = hackFixStupidDjangoDate(event.endTime)
  const now = hackFixStupidDjangoDate()
  return isBefore(endTime, now)
}

const isCurrentlyLive = (event: Event) => {
  if (!event.startTime || !event.endTime) return false
  const startTime = hackFixStupidDjangoDate(event.startTime)
  const endTime = hackFixStupidDjangoDate(event.endTime)
  const now = hackFixStupidDjangoDate()
  return (
    (startTime === now || isAfter(now, startTime)) &&
    (endTime === now || isBefore(now, endTime))
  )
}

export const EventListBlockElement: BlockComponent<EventListBlock> = (
  props,
) => {
  const blocks = props.block.blocks
  const pageChooser = blocks.find((block) =>
    isBlockType(block, 'WAGTAIL_PageChooserBlock'),
  )
  if (
    !isBlockType<EventListBlock_blocks_WAGTAIL_PageChooserBlock>(
      pageChooser,
      'WAGTAIL_PageChooserBlock',
    )
  ) {
    console.warn('No event pages in this EventListBlock', pageChooser)
    return null
  }
  const festivalPage = pageChooser.page
  if (
    !isBlockType<CmsPageData_children_WAGTAIL_HomePage>(
      festivalPage,
      'WAGTAIL_HomePage',
    )
  ) {
    console.warn(
      'Incorrect event parent page type in this EventListBlock',
      festivalPage,
    )
    return null
  }

  let events = festivalPage.events
  const display_mode = getStructFieldOptional<string>(
    props.block,
    'display_mode',
  )?.value

  // Filtered events

  const show_future_events = getStructFieldOptional<boolean>(
    props.block,
    'show_future_events',
  )?.boolean
  const show_currently_live_events = getStructFieldOptional<boolean>(
    props.block,
    'show_currently_live_events',
  )?.boolean
  const show_past_events = getStructFieldOptional<boolean>(
    props.block,
    'show_past_events',
  )?.boolean

  events = events.filter((event) => {
    const filterByTime =
      show_future_events || show_currently_live_events || show_past_events
    const showEverything =
      show_future_events && show_currently_live_events && show_past_events
    const showNothing =
      !show_future_events && !show_currently_live_events && !show_past_events
    // NB: This is probably an admin error
    if (showNothing) return false
    if (!filterByTime || showEverything) return true
    if (show_currently_live_events && isCurrentlyLive(event)) return true
    if (show_future_events && isFuture(event)) return true
    if (show_past_events && isPast(event)) return true
    return false
  })

  /**
   * Calendar display includes some subtantial filtering UI so pass the query
   * And ignore the past/present/future distinction as it doesn't apply here
   * TODO: Separate out Event Calendar Block from Event List BLock
   */

  if (display_mode === 'calendar') {
    return (
      <EventCalendar
        events={events}
        fromDate={hackFixStupidDjangoDate(festivalPage.earliestTime)}
        toDate={hackFixStupidDjangoDate(festivalPage.latestTime)}
      />
    )
  }

  /**
   * EventLists in a grid or list don't have filter options in them,
   * so perform the filtering outside of the component
   */

  const show_search_results_from_url = getStructFieldOptional<boolean>(
    props.block,
    'show_search_results_from_url',
  )?.boolean

  // Grid
  return (
    <EventGrid events={events} isSearchable={!!show_search_results_from_url} />
  )
}

const getDatesBetween = function (start: Date, end: Date): Date[] {
  for (var arr: Date[] = [], dt = hackFixStupidDjangoDate(start); dt <= end; ) {
    arr.push(hackFixStupidDjangoDate(dt))
    dt.setDate(dt.getDate() + 1)
  }
  return arr
}

const getEventsByDate = (events: Event[]): { [date: string]: Event[] } => {
  return events.reduce((dateMap, event) => {
    // Don't plot date-less events on a calendar!
    if (!event.startTime) {
      return dateMap
    }

    const date = format(hackFixStupidDjangoDate(event.startTime), 'yyyy-MM-dd')

    dateMap[date] = Array.isArray(dateMap[date])
      ? [...dateMap[date], event]
      : [event]

    return dateMap
  }, {} as { [date: string]: Event[] })
}

function coerceToArray<T = any>(a: T | T[]): T[] {
  return Array.isArray(a) ? a : [a]
}

const EventCalendar: React.FC<{
  events: Event[]
  fromDate: Date
  toDate: Date
  setQuery?: (q: string) => void
  query?: string
}> = ({ events, fromDate, toDate, setQuery, query }) => {
  const ctx = useCmsPageContext()
  const filterWindow = useWindowOnMobile()
  const [filtersDrawerOpen, setFiltersDrawerOpen] = React.useState(false)

  // Event grid

  const [selectedDates, selectedDateActions] = useArrayState<Date>()
  let filteredEvents = [...events]
  if (selectedDates.length) {
    filteredEvents = filteredEvents.filter((event) =>
      selectedDates.some((selectedDate) =>
        isSameDay(hackFixStupidDjangoDate(event.startTime), selectedDate),
      ),
    )
  }

  // MG: Filters for calendar view
  // Filter select options
  // (restricted by available dates)
  const topics = React.useMemo(
    () =>
      Array.from(
        new Set(
          events.reduce(
            (all, event) => [...all, ...event.topics.map((f) => f.name)],
            [] as string[],
          ),
        ),
      ),
    [events],
  )

  const formations = React.useMemo(
    () =>
      Array.from(
        new Set(
          events.reduce(
            (all, event) =>
              event.formation === 'hybrid' ? all : [...all, event.formation],
            [] as string[],
          ),
        ),
      ),
    [events],
  )

  const formats = React.useMemo(
    () =>
      Array.from(
        new Set(
          events.reduce(
            (all, event) => [...all, ...event.format.map((f) => f.name)],
            [] as string[],
          ),
        ),
      ),
    [events],
  )

  const initialTopics = React.useMemo(
    () =>
      coerceToArray(
        typeof window !== 'undefined'
          ? (qs.parse(window.location.search)?.topic as string[])
          : [],
      ).filter(Boolean),
    [],
  )

  const [selectedTopics, selectedTopicActions] = useArrayState<string>(
    topics.filter((t) =>
      initialTopics.some((T) => t.toLowerCase() === T.toLowerCase()),
    ),
  )

  const initialFormats = React.useMemo(
    () =>
      coerceToArray(
        typeof window !== 'undefined'
          ? (qs.parse(window.location.search)?.format as string[])
          : [],
      ).filter(Boolean),
    [],
  )

  if (selectedTopics.length) {
    filteredEvents = filteredEvents.filter((event) =>
      selectedTopics.some((selected) =>
        event.topics?.some((item) => item.name === selected),
      ),
    )
  }

  const [selectedFormats, selectedFormatActions] = useArrayState<string>(
    formats.filter((t) =>
      initialFormats.some((T) => t.toLowerCase() === T.toLowerCase()),
    ),
  )
  
  if (selectedFormats.length) {
    filteredEvents = filteredEvents.filter((event) =>
      selectedFormats.some((selected) =>
        event.format?.some((item) => item.name === selected),
      ),
    )
  }
  
  const formationFromQueryString = () => {
    return qs.parse(window.location.search)?.formation as string[]
  };

  const initialFormations = React.useMemo(
    () =>
      coerceToArray(
        typeof window !== 'undefined'
          ? formationFromQueryString()
          : [],
      ).filter(Boolean),
    [],
  )

  const [selectedFormations, selectedFormationActions] = useArrayState<string>(
    formations.filter((t) =>
      initialFormations.some((T) => t.toLowerCase() === T.toLowerCase()),
    ),
  )

  if (selectedFormations.length) {
    let realSelectedFormations = [...selectedFormations];
    
    // Online events should include those that are also hybrid
    if (selectedFormations.includes('online')) {
      realSelectedFormations.push('hybrid')
    }

    filteredEvents = filteredEvents.filter((event) =>
      realSelectedFormations.some((selected) => event.formation === selected),
    )
  }

  // Date picker logic
  const emptyDates = React.useMemo(() => {
    const eventsByDate = getEventsByDate(events)
    const dateRange = getDatesBetween(fromDate, toDate)
    const filledDates = Object.keys(eventsByDate).map((dateString) =>
      hackFixStupidDjangoDate(dateString),
    )
    return dateRange.filter(
      (date) => !filledDates.some((filledDate) => isSameDay(date, filledDate)),
    )
  }, [fromDate, toDate, events])

  const hasFilters = !!(
    selectedTopics.length ||
    selectedFormats.length ||
    selectedDates.length ||
    selectedFormations.length
  )

  const compatibleDates = React.useMemo(() => {
    if (!hasFilters) return []
    const eventsByDate = getEventsByDate(filteredEvents)
    const dateRange = getDatesBetween(fromDate, toDate)
    const filledDates = Object.keys(eventsByDate).map((dateString) =>
      hackFixStupidDjangoDate(dateString),
    )
    return dateRange.filter((date) =>
      filledDates.some((filledDate) => isSameDay(date, filledDate)),
    )
  }, [hasFilters, fromDate, toDate, filteredEvents])

  const theme = useTheme()

  // MG: Side Calendar
  // 98.646px
  return (
    <>
      <Petal
        src="/petal-1.png"
        initial={{ opacity: 0, y: -50, rotate: 10, scale: 0.8 }}
        animate={{ opacity: 1, y: 0, rotate: 0 }}
        transition={{ duration: 3.5, delay: 1.5 }}
      />
      <Petal
        src="/petal-5.png"
        initial={{ opacity: 0, y: -50, scale: 0.5, rotate: 5 }}
        animate={{ opacity: 1, y: 0, rotate: 0 }}
        transition={{ duration: 5 }}
      />
      <EmptyButton
        onClick={() => setFiltersDrawerOpen(!filtersDrawerOpen)}
      >
        <Rows
          bg="pink"
          justifyContent="space-between"
          flexShrink={0}
          paddingHorizontal={{ mobile: 4, desktop: 4 }}
          paddingBottom={{
            mobile: filtersDrawerOpen ? 1 : 3,
            desktop: filtersDrawerOpen ? 1 : 3,
          }}
          paddingTop={3}
          flip
        >
          <Text variant="filterButton">
            Filter
            {filtersDrawerOpen ? ':' : ''}
          </Text>
          <Text variant="filterButton">{filtersDrawerOpen ? '-' : '+'}</Text>
        </Rows>
      </EmptyButton>
      <Columns flip>
        <WindowOnMobile
          bg="#FFC1FF"
          color="#1E008E"
          flexShrink={0}
          showFrom="mobile"
          {...filterWindow}
        >
          {filtersDrawerOpen && (
            <Rows
              alignItems="flex-start"
              spacing={3}
              paddingTop={{ mobile: 2, desktop: 2 }}
              paddingBottom={{ mobile: 4, desktop: 4 }}
              paddingHorizontal={{ mobile: 4, desktop: 4 }}
              flip
              css={css`
                @media screen and (max-width: 767px) {
                  flex-direction: column;
                  width: 100%;
                }
              `}
            >
              <Rows 
                flexGrow={1} 
                width="50%" 
                css={css`
                  @media screen and (max-width: 767px) {
                    flex-direction: column;
                    width: 100%;
                  }
                `}
              >
                <ChipGrid
                  css={css`
                    @media screen and (max-width: 767px) {
                      flex-direction: row;
                    }
                  `} 
                >
                  {['in_person', 'online'].map((item) => {
                    const index = selectedFormations.indexOf(item)
                    
                    return (
                      <Button
                        key={item}
                        onClick={() => {
                          if (index > -1) {
                            selectedFormationActions.remove(index)
                            selectedFormationActions.remove(0)
                          }
                          
                          if (index <= 0) {
                            selectedFormationActions.unshift('hybrid')
                            selectedFormationActions.push(item)
                          }
                        }}
                        variant={index > -1 ? 'toggleChipActive' : 'toggleChip'}
                      >
                        {formatFormation(item)}
                      </Button>
                    )
                  })}
                </ChipGrid>
                
                <Calendar
                  css={css`
                    @media screen and (min-width: 767px) {
                      display: none;
                    }
                  `}
                  showOutsideDays
                  fromMonth={fromDate}
                  toMonth={toDate}
                  initialMonth={fromDate}
                  disabledDays={[
                    {
                      before: fromDate,
                    },
                    ...emptyDates,
                    {
                      after: toDate,
                    },
                  ]}
                  modifiers={{ compatible: compatibleDates }}
                  modifiersStyles={{ compatible: { color: theme.colors.indigo } }}
                  canChangeMonth={getMonth(fromDate) === getMonth(toDate)}
                  selectedDays={selectedDates}
                  onDayClick={(day) => {
                    if (
                      emptyDates.some((selectedDate) =>
                        isSameDay(selectedDate, day),
                      )
                    )
                      return

                    const index = selectedDates.findIndex((selectedDate) =>
                      isSameDay(selectedDate, day),
                    )

                    if (index > -1) {
                      selectedDateActions.remove(index)
                    } else {
                      selectedDateActions.push(day)
                    }
                  }}
                />

                <Box 
                  marginTop={3}
                  css={css`
                    @media screen and (max-width: 767px) {
                      display: none;
                    }
                  `}
                >
                  <Text variant="filterButton">Formats</Text>
                  <ChipGrid marginTop={1}>
                    {formats.map((item) => {
                      const index = selectedFormats.indexOf(item)
                      return (
                        <Button
                          key={item}
                          onClick={() => {
                            if (index > -1) {
                              selectedFormatActions.remove(index)
                            } else {
                              selectedFormatActions.push(item)
                            }
                          }}
                          variant={
                            index > -1 ? 'squareChipActive' : 'squareChip'
                          }
                        >
                          {item}
                        </Button>
                      )
                    })}
                  </ChipGrid>
                </Box>
              </Rows>
              <Rows 
                spacing={2} 
                width="50%" 
                flexGrow={1} 
                css={css`
                  @media screen and (max-width: 767px) {
                    flex-direction: column;
                    width: 100%;
                  }
                `}
              >
                <Text variant="filterButton">Topics</Text>
                <ChipGrid marginTop={1}
                  css={css`
                    @media screen and (max-width: 767px) {
                      flex-direction: column;
                    }
                  `}
                >
                  {topics.map((item) => {
                    const index = selectedTopics.indexOf(item)
                    return (
                      <Button
                        key={item}
                        onClick={() => {
                          if (index > -1) {
                            selectedTopicActions.remove(index)
                          } else {
                            selectedTopicActions.push(item)
                          }
                        }}
                        variant={index > -1 ? 'squareChipActive' : 'squareChip'}
                      >
                        {item}
                      </Button>
                    )
                  })}
                </ChipGrid>
              </Rows>
              <Calendar
                css={css`
                  @media screen and (max-width: 767px) {
                    display: none;
                  }
                `}
                showOutsideDays
                fromMonth={fromDate}
                toMonth={toDate}
                initialMonth={fromDate}
                disabledDays={[
                  {
                    before: fromDate,
                  },
                  ...emptyDates,
                  {
                    after: toDate,
                  },
                ]}
                modifiers={{ compatible: compatibleDates }}
                modifiersStyles={{ compatible: { color: theme.colors.indigo } }}
                canChangeMonth={getMonth(fromDate) === getMonth(toDate)}
                selectedDays={selectedDates}
                onDayClick={(day) => {
                  if (
                    emptyDates.some((selectedDate) =>
                      isSameDay(selectedDate, day),
                    )
                  )
                    return

                  const index = selectedDates.findIndex((selectedDate) =>
                    isSameDay(selectedDate, day),
                  )

                  if (index > -1) {
                    selectedDateActions.remove(index)
                  } else {
                    selectedDateActions.push(day)
                  }
                }}
              />
            </Rows>
          )}
        </WindowOnMobile>

        <Box
          padding={{ mobile: 2, desktop: 3 }}
          marginTop={{ mobile: 2, desktop: 0 }}
          width="100%"
        >
          <Rows spacing={3}>
            <EventGrid events={filteredEvents} />
          </Rows>
        </Box>
      </Columns>
    </>
  )
}

const Petal = styled(motion.img)`
  position: fixed;
  pointer-events: none;
  z-index: -1;
  top: -5vw;
  left: -55vw;
  width: 100%;
  height: auto;
  overflow: hidden;

  &:last-of-type {
    left: inherit;
    top: -20vw;
    right: -40vw;
  }
`

const updateURLQueryParam = debounce((query: string) => {
  if (typeof window !== 'undefined' && window.history.pushState) {
    const path = qs.stringifyUrl({
      url: window.location.toString(),
      query: { query },
    })
    window.history.replaceState({ path }, '', path)
  }
}, 250)

export const EventGrid: React.FC<{
  events: Event[]
  isSearchable?: boolean
}> = ({ events, isSearchable }) => {
  // const initialQuery =
  //   isSearchable && typeof window !== 'undefined'
  //     ? String(qs.parse(window.location.search)?.query)
  //     : undefined
  // const { query, setQuery, results } = useEventSearch(events, {
  //   initialQuery,
  //   blankQueryPolicy: 'show-all',
  // })

  // React.useEffect(() => {
  //   updateURLQueryParam(query)
  // }, [query])

  // MG: This is the Grid which captures all the Event Lists / Programme Cells
  return (
    <>
      {events.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </>
  )
}

export const eventListFragments = graphql`
  fragment EventListBlock on WAGTAIL_StructBlock {
    id
    blocks {
      id
      ... on WAGTAIL_PageChooserBlock {
        id
        page {
          ... on WAGTAIL_HomePage {
            title
            slug
            earliestTime
            latestTime
            events {
              ...Event
              ...EventSearchResult
            }
          }
        }
      }
      ... on WAGTAIL_ChoiceBlock {
        field
        value
      }
      ... on WAGTAIL_BooleanBlock {
        field
        boolean: value
      }
    }
  }
`
