import { graphql } from 'gatsby'
import { createPortal } from 'react-dom'
import { FC, useState, useEffect, useMemo } from 'react'
import {
  Rows,
  IconButton,
  CloseIcon,
  Content,
  Bold,
  ButtonAccessory,
  useTheme,
  NoSSR,
} from '../../design-system'
import React from 'react'
import { colors } from '../theme'
import { ButtonLink, ArrowRight } from '..'
import { shuffle } from 'lodash'
import { addDays } from 'date-fns'
import { isSchemaType } from '../../cms/cms.util'
import { PopupConfig } from './__generated__/PopupConfig'
import { Popup } from './__generated__/Popup'
import { ArrowLink } from '../molecules/arrow-link'

export const popupsFragment = graphql`
  fragment PopupConfig on WAGTAIL_Popups {
    children {
      ...Popup
    }
  }

  fragment Popup on WAGTAIL_Popup {
    slug
    message
    callToAction
    localLink {
      url
    }
    webLink
    frequencyInDays
  }
`

const useFooterPop = () =>
  useMemo(() => {
    if (typeof document === 'undefined') {
      return undefined
    }

    let el = document.getElementById('foooter-pop')
    if (!el) {
      el = document.createElement('div')
      el.id = 'footer-pop'
      document.body.appendChild(el)
    }

    return el
  }, [])

const getPopupKey = (popup: Popup) => 'dismiss-popup-' + popup.slug

const findPopupToShow = (popups: PopupConfig) => {
  if (!popups) {
    return []
  }

  const shuffledPopups = shuffle(popups.children)
  return shuffledPopups.find(p => {
    if (!isSchemaType(p.__typename, 'WAGTAIL_Popup')) {
      return false
    }

    if (typeof localStorage === 'undefined') {
      return false
    }

    const lastDismissedDate = localStorage.getItem(getPopupKey(p))
    if (!lastDismissedDate) {
      return true
    }

    return addDays(lastDismissedDate, Number(p.frequencyInDays)) < new Date()
  })
}

export const Popups: FC<{ popups: PopupConfig }> = ({ popups }) => {
  const popupEl = useFooterPop()

  const sessionDismissed =
    typeof sessionStorage !== 'undefined' &&
    sessionStorage.getItem('dismiss-support')

  const popupToShow = findPopupToShow(popups)

  const [show, setShow] = useState(
    sessionDismissed ? false : Math.random() < 0.3,
  )

  useEffect(() => {
    if (!show) {
      sessionStorage.setItem('dismiss-popup', new Date().toISOString())
    }
  }, [])

  if (!show || !popupToShow) {
    return null
  }

  return (
    <NoSSR>
      <PopupInstance
        popup={popupToShow}
        el={popupEl}
        onClose={() => {
          setShow(false)
          localStorage.setItem(
            getPopupKey(popupToShow),
            new Date().toISOString(),
          )
          sessionStorage.setItem(
            getPopupKey(popupToShow),
            new Date().toISOString(),
          )
        }}
      />
    </NoSSR>
  )
}

const PopupInstance: FC<{
  popup: Popup
  el: HTMLElement
  onClose: () => void
}> = ({ popup, el, onClose }) => {
  const theme = useTheme()
  return createPortal(
    <Rows
      margin={-1}
      padding={3}
      bg="white"
      left={0}
      bottom={0}
      width="calc(100% + 8px)"
      position="fixed"
      border={`2px solid ${theme.color('contentAlt')}`}
      spacing={2}
      z={100}
    >
      <IconButton onClick={onClose} position="absolute" right={2} top={2}>
        <CloseIcon />
      </IconButton>
      <Content variant="small">
        <span dangerouslySetInnerHTML={{ __html: popup.message }} />
      </Content>
      <ArrowLink
        href={(popup.localLink && popup.localLink.url) || popup.webLink}
        alignSelf="flex-end"
        onClick={onClose}
      >
        {popup.callToAction}
      </ArrowLink>
    </Rows>,
    el,
  )
}
