import * as React from 'react'
import { graphql } from 'gatsby';
import { useState, useEffect} from 'react';
import { Link } from '../../design-system/atoms/links';
import { CmsPageData_children_WAGTAIL_HomePage } from '../../cms/__generated__/CmsPageData';
import { EventListBlock_blocks_WAGTAIL_PageChooserBlock } from '../organisms/__generated__/EventListBlock';
import { getStructFieldOptional, BlockComponent, Debug } from '../../cms/cms.util';
import { css } from '@emotion/core';
import Fuse from 'fuse.js'
import useMemo from 'react';
import { EventSearchResult } from './__generated__/EventSearchResult';
import { EventSearchBarBlock } from './__generated__/EventSearchBarBlock';
import { get } from 'lodash';
import { useEventSearch } from '../event-search-engine';

export const EventSearchBarBlockElement: BlockComponent<EventSearchBarBlock> = ({ block }) => {
  const rootPage: CmsPageData_children_WAGTAIL_HomePage = block.blocks.find(block =>
    block.field === 'root_page'
  )?.page
  const { events } = rootPage

  if (!events) return null

  const searchPage: CmsPageData_children_WAGTAIL_HomePage = block.blocks.find(block =>
    block.field === 'search_results_page'
  )?.page

  return <SearchBar
    maxResultsCount={3}
    events={events}
    searchPageURL={searchPage?.url}
  />
}

const SearchBar: React.FC<{
  searchPageURL?: string
  maxResultsCount?: number
  events?: EventSearchResult[]
}> = ({ searchPageURL, maxResultsCount = 3, events = [] }) => {
  const { setQuery, query, results } = useEventSearch(events)

  return (
    <div>
      <div>
        <input type="text" value={query} onChange={e => setQuery(e.target.value)} />
        🔍
      </div>
      {results.length > 0 && (
        <div>
          {results.slice(0, maxResultsCount).map(result => (
            <Link to={result.item.url} key={result.item.id} style={{ display: 'block' }}>
              <b>{result.item.title}</b>
              {result.matches.filter(m => m.key !== 'title').map((match, i) => {
                return (
                  <div key={i}>
                    <span style={{ textTransform: 'capitalize', opacity: 0.5 }}>
                      {humaniseSearchKey(match)}
                    </span>
                    &nbsp;
                    <span>
                      {humaniseSearchResult(match)}
                    </span>
                  </div>
                )
              })}
            </Link>
          ))}
          {searchPageURL && (
            <div>
              <Link to={`${searchPageURL}?query=${encodeURIComponent(query)}`}>See more results ➡️</Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const humaniseSearchKey = (match: Fuse.FuseResultMatch) => match.key.replace('.name', '').replace('.', ' > ')

const humaniseSearchResult = (match: Fuse.FuseResultMatch, resultMaxLength = 30) => {
  return match.value.length <= resultMaxLength ? (
    match.value 
  ) : (
    match.indices[0][0] > 0 ? '... ' : ''
  ) + match.value.slice(
    Math.max(0, match.indices[0][0] - resultMaxLength * 0.333),
    Math.min(match.indices[0][1] + resultMaxLength * 0.666, match.value.length)
  ) + (
    match.indices[0][1] < match.value.length ? '...' : ''
  )
}

export const eventSearchBarBlockFragments = graphql`
  fragment EventSearchBarBlock on WAGTAIL_StructBlock {
    id
    blocks {
      id
      ...on WAGTAIL_PageChooserBlock {
        id
        field
        page {
          ... on WAGTAIL_HomePage {
            url
            title
            slug
            earliestTime
            latestTime
            events {
              ... EventSearchResult
            }
          }
        }
      }
      ... on WAGTAIL_ChoiceBlock {
        field
        value
      }
    }
  }
`
