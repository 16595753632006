import * as React from 'react'
import { BlockComponent, isBlockType } from '../../cms/cms.util'
import { graphql } from 'gatsby'
import {
  EventListBlock,
  EventListBlock_blocks_WAGTAIL_PageChooserBlock,
} from './__generated__/EventListBlock'
import { CmsPageData_children_WAGTAIL_HomePage } from '../../cms/__generated__/CmsPageData'
import { EventGroupCard } from '../molecules/event-group-card'
import { Grid } from '../../design-system'

export const EventGroupListBlockElement: BlockComponent<EventListBlock> = (
  props,
) => {
  const pageChooser = props.block.blocks.find((block) =>
    isBlockType(block, 'WAGTAIL_PageChooserBlock'),
  )
  if (
    !isBlockType<EventListBlock_blocks_WAGTAIL_PageChooserBlock>(
      pageChooser,
      'WAGTAIL_PageChooserBlock',
    )
  ) {
    console.warn('No event pages in this EventListBlock', pageChooser)
    return null
  }
  const festivalPage = pageChooser.page
  if (
    !isBlockType<CmsPageData_children_WAGTAIL_HomePage>(
      festivalPage,
      'WAGTAIL_HomePage',
    )
  ) {
    console.warn(
      'Incorrect event parent page type in this EventListBlock',
      festivalPage,
    )
    return null
  }

  return (
    <Grid
      justifyContent="stretch"
      justifyItems="stretch"
      alignItems="stretch"
      width="100%"
      columnGap={{ mobile: 2, tablet: 3 }}
      columnSize={{ mobile: 176, tablet: 306 }}
    >
      {festivalPage?.eventGroups?.map((eventGroup) => (
        <EventGroupCard key={eventGroup.id} eventGroup={eventGroup} />
      ))}
    </Grid>
  )
}

export const eventGroupListFragments = graphql`
  fragment EventGroupListBlock on WAGTAIL_StructBlock {
    blocks {
      id
      ... on WAGTAIL_PageChooserBlock {
        page {
          ... on WAGTAIL_HomePage {
            eventGroups {
              ...EventGroup
            }
          }
        }
      }
    }
  }
`
