export const TroisMille = {
  name: 'TroisMille',
  decls: [
    {
      weight: 600,
      src: [require('./TroisMilleBold-18.woff')]
    },
    {
      weight: 600,
      style: 'italic',
      src: [require('./TroisMilleBoldItalic-18.woff')]
    }
  ]
}
