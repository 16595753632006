import {
  BlockComponent,
  getStructField,
  extractLinkFromFragment,
} from './cms.util'
import { graphql } from 'gatsby'
import { chunk } from 'lodash'
import { HeroUnit } from '../twt-core/organisms/hero-unit'
import {
  EmailLink,
  PhoneLink,
  Panel,
  Content,
  Subheading,
  Columns,
  Text,
  Heading,
  Rows,
  SepH,
} from '../design-system'
import { TeaserBlock, Teaser2 } from '../twt-2020'
import React, { Fragment } from 'react'
import { UniversalBlockElement } from './universal-blocks'
import { ContentBlock } from './__generated__/ContentBlock'
import { ContactBlock } from './__generated__/ContactBlock'
import { SectionBlock } from './__generated__/SectionBlock'
import { StreamBlock } from './stream-block'
import { SectionElement, getSectionProps } from './section'
import { SectionContent } from './__generated__/SectionContent'
import { BannerBlock } from './__generated__/BannerBlock'
import { BannerBlockImage } from './__generated__/BannerBlockImage'
import { ContactBlockContactList } from './__generated__/ContactBlockContactList'
import { ContactBlockContact } from './__generated__/ContactBlockContact'
import { ContactBlockContactDetail } from './__generated__/ContactBlockContactDetail'
import { useCmsPageContext, TWT2020Theme, FullPage } from './context'
import { FeatureCard } from '../twt-core/organisms'

// Full Page Block

export const fullPageBlockFragment = graphql`
  fragment FullPageBlock on WAGTAIL_StructBlock {
    ...SectionBase

    blocks {
      field
      ...SectionContent
    }
  }
`

const FullPageBlockElement: BlockComponent<FullPageBlock> = ({ block }) => {
  const content = getStructField<SectionContent>(block, 'content').blocks

  const contentBlocks = content.map((item) => (
    <UniversalBlockElement key={item.id} block={item} />
  ))

  return <FullPage>{contentBlocks}</FullPage>
}

// Content Block

export const contentBlockFragment = graphql`
  fragment ContentBlock on WAGTAIL_StructBlock {
    ...SectionBase

    blocks {
      field
      ...SectionContent
    }
  }

  fragment SectionContent on WAGTAIL_StreamBlock {
    blockType
    blocks {
      field
      ...UniversalBlock
    }
  }
`

const ContentBlockElement: BlockComponent<ContentBlock> = ({
  block,
  ...props
}) => {
  const content = getStructField<SectionContent>(block, 'content').blocks

  const contentBlocks = content.map((item) => (
    <UniversalBlockElement key={item.id} block={item} />
  ))

  return (
    <SectionElement context="section" block={block} {...props}>
      {contentBlocks}
    </SectionElement>
  )
}

// Feature Block

const FeatureBlockElement: BlockComponent<ContentBlock> = ({
  block,
  ...props
}) => {
  const { title, style } = getSectionProps(block)
  const content = getStructField<SectionContent>(block, 'main_content').blocks
  const contextualContent = getStructField<SectionContent>(
    block,
    'promotional_content',
  ).blocks

  return (
    <SectionElement block={block} context="feature" {...props}>
      <FeatureCard
        content={content.map((item) => (
          <UniversalBlockElement key={item.id} block={item} />
        ))}
        contextualContent={contextualContent.map((item) => (
          <UniversalBlockElement showFrom="tablet" key={item.id} block={item} />
        ))}
        title={title}
        style={style}
        index={props.index}
      />
    </SectionElement>
  )
}

// Banner

export const bannerBlockFragment = graphql`
  fragment BannerBlock on WAGTAIL_StructBlock {
    ...SectionBase

    blocks {
      field
      ...LinkFragment
      ...BannerBlockImage
    }
  }

  fragment BannerBlockImage on WAGTAIL_ImageChooserBlock {
    image {
      ...ImageFragment
    }
  }
`

const BannerBlockElement: BlockComponent<BannerBlock> = ({
  block,
  ...props
}) => {
  const { title } = getSectionProps(block)
  const ctx = useCmsPageContext()
  const content = getStructField<ContentBlock>(block, 'content').blocks

  if (ctx.theme === TWT2020Theme) {
    if (props.index === 0) {
      return (
        <TeaserBlock
          heading={title}
          linkUrl={extractLinkFromFragment(block)}
          imageUrl={getStructField<BannerBlockImage>(block, 'image').image.src}
        >
          {content.map((item, i) => (
            <UniversalBlockElement key={i} block={item} />
          ))}
        </TeaserBlock>
      )
    }
    if (props.index > 0) {
      return (
        <Teaser2
          heading={title}
          linkUrl={extractLinkFromFragment(block)}
          imageUrl={getStructField<BannerBlockImage>(block, 'image').image.src}
        >
          {content.map((item, i) => (
            <UniversalBlockElement key={i} block={item} />
          ))}
        </Teaser2>
      )
    }
  }

  return (
    <SectionElement block={block} context="banner" {...props}>
      <Rows spacing={3}>
        <HeroUnit
          heading={title}
          linkUrl={extractLinkFromFragment(block)}
          imageUrl={getStructField<BannerBlockImage>(block, 'image').image.src}
        />

        {content.length > 0 && (
          <Panel spacing={3}>
            {content.map((item, i) => (
              <UniversalBlockElement key={i} block={item} />
            ))}
          </Panel>
        )}
      </Rows>
    </SectionElement>
  )
}

// Contacts

export const contactBlockFragment = graphql`
  fragment ContactBlock on WAGTAIL_StructBlock {
    ...SectionBase

    blocks {
      field
      ...ContactBlockContactList
    }
  }

  fragment ContactBlockContactList on WAGTAIL_ListBlock {
    items {
      ...ContactBlockContact
    }
  }

  fragment ContactBlockContact on WAGTAIL_StructBlock {
    id
    blocks {
      ...ContactBlockContactDetail
    }
  }

  fragment ContactBlockContactDetail on WAGTAIL_StreamFieldInterface {
    field
    rawValue
  }
`

const ContactsBlockElement: BlockComponent<ContactBlock> = ({ block }) => {
  const { title } = getSectionProps(block)
  const contacts = getStructField<ContactBlockContactList>(block, 'contacts')
    .items as ContactBlockContact[]
  const columns = chunk(contacts, 2)

  return (
    <SectionElement context="contacts" block={block}>
      <Panel alignSelf="center">
        {title && <Heading>{title}</Heading>}

        {columns.map((c) => {
          return (
            <Fragment key={c.id}>
              <Columns
                spacing={{ mobile: 0, tablet: 4 }}
                flipSpacing={4}
                flip={{ mobile: true, tablet: false }}
              >
                {c.map((item) => {
                  const name = getStructField<ContactBlockContactDetail>(
                    item,
                    'name',
                  ).rawValue
                  const email = getStructField<ContactBlockContactDetail>(
                    item,
                    'email',
                  ).rawValue
                  const phone = getStructField<ContactBlockContactDetail>(
                    item,
                    'phone',
                  ).rawValue
                  const notes = getStructField<ContactBlockContactDetail>(
                    item,
                    'notes',
                  ).rawValue

                  return (
                    <Content>
                      <Subheading>{name}</Subheading>
                      {email && <EmailLink address={email} />}
                      {phone && <PhoneLink address={phone} />}
                      {notes && <Text>{notes}</Text>}
                    </Content>
                  )
                })}
              </Columns>

              <SepH showFrom="tablet" offset={-2} />
            </Fragment>
          )
        })}
      </Panel>
    </SectionElement>
  )
}

// Section Block

export const sectionBlockFragment = graphql`
  fragment SectionBlock on WAGTAIL_StreamFieldInterface {
    field
    ...FullPageBlock
    ...ContentBlock
    ...ContactBlock
    ...BannerBlock
  }
`

export const SectionBlockElement: BlockComponent<SectionBlock> = (props) => (
  <StreamBlock
    blockTypes={{
      BannerBlock: BannerBlockElement,
      ContactBlock: ContactsBlockElement,
      ContentBlock: ContentBlockElement,
      FeatureBlock: FeatureBlockElement,
      FullPage: FullPageBlockElement,
    }}
    {...props}
  />
)
