import React from 'react'
import { SvgGraphicProps, SvgGraphic } from '../../design-system'

export const DocumentGraphic = (props: SvgGraphicProps) => (
  <SvgGraphic viewBox="0 0 51 67" fill="none" {...props}>
    <g clipPath="url(#prefix__clip0)" fill="#56C1AD">
      <path d="M50.797 4.957v61.555H5.233V63.02h42.075V4.957h3.49zM10.819.988c-.027.163-.054.313-.04.476-.014.027-.014.04-.014.068v8.97H1.974c-.081 0-.149 0-.23.013a2.8 2.8 0 00-.487.082l.487-.49 3.49-3.506L10.818.988z" />
      <path d="M46.361.512v61.556H.797V12.05c.257-.244.582-.394.947-.435.067-.027.149-.027.23-.027h9.873V1.464a1.69 1.69 0 01.338-.952H46.36z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(.797 .512)" d="M0 0h50v66H0z" />
      </clipPath>
    </defs>
  </SvgGraphic>
)
