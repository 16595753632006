import { image } from '../../design-system'

export * from './arrow-right'
export * from './burger'
export * from './construction'

export const BALogo = image({ url: require('./BA.png') })
export const MomentumLogo = image({
  url: require('./Momentum---red-on-white.png'),
})
export const CWULogo = image({ url: require('./cwu logo white.png') })
export const Crowd = image({ url: require('./crowd.png') })
export const Crowd2 = image({ url: require('./crowd2.jpg') })
export const CrowdLarge = image({
  url: require('./socialism.jpg'),
  objectPosition: 'bottom',
})
export const RoundTable = image({
  url: require('./roundtable.jpeg'),
  objectPosition: '50% 30%',
})
export const TWT19 = image({
  url: require('./twt19.jpg'),
  objectPosition: 'center',
})
