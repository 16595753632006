import React, { FC } from 'react'

import {
  PageDisplayProps,
  HomePageType,
  CategoryPageType,
  ListPageType,
} from '../../cms'
import { Display, Columns, ResponsiveImage } from '../../design-system'
import { LayoutCard } from '../../twt-core'

export const PageTitle2020: FC<PageDisplayProps> = ({
  type,
  colour,
  image,
  title,
}) => {
  if (type === CategoryPageType && image) {
    return (
      <Columns
        spacing={2}
        flipSpacing={2}
        flip={{ mobile: true, desktop: false }}
      >
        <Columns
          showFrom="desktop"
          padding={3}
          justifyContent="flex-start"
          width={{ mobile: '100%', desktop: '50%' }}
        >
          <ResponsiveImage width="500px" flexShrink={1} image={image} />
        </Columns>

        <LayoutCard
          width={{ mobile: '100%', desktop: '50%' }}
          bg={colour}
          marginVertical={0}
        >
          <Display>{title}</Display>
        </LayoutCard>
      </Columns>
    )
  }

  if (type === ListPageType && image) {
    return (
      <Columns
        spacing={2}
        flipSpacing={2}
        flip={{ mobile: true, tablet: false }}
      >
        <ResponsiveImage showUntil="desktop" height="234px" image={image} />

        <LayoutCard width="50%" marginVertical={0}>
          <Display>{title}</Display>
        </LayoutCard>

        <ResponsiveImage
          showFrom="desktop"
          height="381px"
          width="50%"
          flexShrink={1}
          image={image}
        />
      </Columns>
    )
  }

  if (type === HomePageType) {
    return null
  }

  return (
    <LayoutCard marginVertical={0}>
      <Display>{title}</Display>
    </LayoutCard>
  )
}
