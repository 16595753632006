import React, {
  FC,
  ReactElement,
  cloneElement,
  CSSProperties,
  InputHTMLAttributes,
} from 'react'
import { Field as FieldState } from 'react-jeff'
import {
  spacingLevel,
  screens,
  BoxProps,
  styled,
  Box,
  Rows,
  Columns,
  CaptionSmall,
  Checkmark,
  FlexProps,
  useTheme,
} from '../../design-system'

interface FieldProps extends BoxProps, InputHTMLAttributes<{}> {
  disabled?: boolean
}

export const Field = styled(Box)<FieldProps>`
  ${({ theme }) => theme.font('regular')};
  font-weight: 300;

  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  padding-right: ${spacingLevel(1)}px;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.color('control')};
  outline: none;
  text-decoration: none;
  overflow: visible;
  width: 12em;

  ${({ disabled }) =>
    disabled
      ? 'opacity: 0.5;'
      : `
    &:focus-within,
    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.color('contentAlt')};
    }
  `}

  font-size: 26px;

  @media (mid-width: ${screens.desktop}px) {
    font-size: 32px;
  }
`

export const Control: FC<
  {
    label: string
    labelStyle?: CSSProperties
    helpText?: string
    children: ReactElement<{ field?: FieldState<{}> }>
  } & FlexProps
> = ({ label, children, labelStyle, helpText, ...props }) => {
  const theme = useTheme()
  const field = children.props.field

  return (
    <Rows spacing={1} {...props}>
      <CaptionSmall style={{ opacity: 0.8, fontWeight: 500, ...labelStyle }}>
        {label}:
      </CaptionSmall>

      {cloneElement(children, { 'aria-label': label, minWidth: '100%' })}

      <CaptionSmall
        style={{
          color:
            !field || (field.dirty && field.valid)
              ? undefined
              : theme.color('darkPink'),
          opacity: 0.8,
          minHeight: '3ex',
        }}
      >
        {field && field.dirty && field.errors.length > 0
          ? field.errors[0]
          : helpText}
      </CaptionSmall>
    </Rows>
  )
}

const AnimatedCheck = styled(Checkmark)`
  transition: opacity 0.15s ease-in-out;
`

const CheckboxWrapper = styled(Columns)`
  user-select: none;
  cursor: pointer;
`

export const Checkbox: FC<
  { label?: string; disabled?: boolean; field?: FieldState<boolean> } & BoxProps
> = ({ label, field, disabled, ...props }) => {
  const handleClick = () => field.props.onChange(!field.props.value)
  const theme = useTheme()

  return (
    <CheckboxWrapper
      alignItems="center"
      onClick={handleClick}
      spacing={1}
      {...props}
    >
      <Columns
        justifyContent="center"
        alignItems="center"
        width="1em"
        height="1em"
        flexShrink={0}
        color={theme.color('contentAlt')}
        border={`1px solid ${theme.color('control')}`}
        borderRadius={3}
      >
        <AnimatedCheck
          width="0.75em"
          height="0.75em"
          opacity={field.props.value ? 1 : 0}
        />
      </Columns>
      <CaptionSmall>{label}</CaptionSmall>
      <input
        style={{ display: 'none' }}
        type="checkbox"
        checked={field.props.value}
        onClick={handleClick}
      />
    </CheckboxWrapper>
  )
}
