import React, { ReactNode } from 'react'
import { TwitterIcon, LinkIcon, Link, Columns, Text } from '../../design-system'

interface ProfileLinkProps {
  value?: string
  children?: ReactNode
  color?: string
}

const InlineLink = Columns.withComponent(Link)

export const TwitterProfileLink = ({
  value,
  children = 'Twitter',
  ...props
}: ProfileLinkProps) =>
  !value ? null : (
    <InlineLink
      alignItems="center"
      spacing={1}
      to={'https://twitter.com/' + value}
      {...props}
    >
      <TwitterIcon width="1em" />
      <Text variant="control">{children}</Text>
    </InlineLink>
  )

export const WebsiteProfileLink = ({
  value,
  children = value &&
    value.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, ''),
  ...props
}: ProfileLinkProps) =>
  !value ? null : (
    <InlineLink alignItems="center" spacing={1} to={value} {...props}>
      <LinkIcon width="1em" />
      <Text variant="control">{children}</Text>
    </InlineLink>
  )
