import React, { ButtonHTMLAttributes, ReactElement, cloneElement } from 'react'
import {
  Text,
  BoxProps,
  styled,
  Box,
  spacingLevel,
  Columns,
  mapResponsive,
} from '../../design-system'
import { ChipButton, Chip, ChipLink } from '../atoms'
import { noop } from 'lodash'

interface ToggleChipProps extends BoxProps, ButtonHTMLAttributes<{}> {
  selected?: boolean
  linkTo?: string
}

export const ToggleChip = ({
  children,
  selected,
  linkTo,
  ...props
}: ToggleChipProps) => {
  const Component = linkTo ? ChipLink : props.onClick ? ChipButton : Chip

  return (
    <Component
      bg={selected ? 'contentAlt' : 'bg'}
      padding={1}
      color="contentAlt"
      flexShrink={0}
      justifyContent="center"
      minWidth="5em"
      maxWidth={{
        tablet:
          typeof children === 'string'
            ? Math.max(children.length, 10) + 'em'
            : '10em',
      }}
      overflow="hidden"
      to={linkTo}
      {...props}
    >
      {children}
    </Component>
  )
}

const ChipGridWrapper = styled.div<{ grow?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  margin: ${spacingLevel(-1)}px;
  margin-bottom: 0;
  flex-shrink: 0;

  > * {
    margin: ${spacingLevel(1)}px;
    margin-left: 0;
    flex-grow: ${({ grow }) => (grow ? '1' : '0')};
    min-width: 0;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`

const ChipRadioButtonWrapper = styled(Columns)`
  > *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  > *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

interface ChipRadioButtonsProps extends Omit<BoxProps, 'onChange'> {
  onChange?: (value: string) => void
  value: string
  children: ReactElement<ToggleChipProps>[]
}

export const ChipRadioButtons = ({
  onChange = noop,
  value,
  children,
  color,
  padding = 2,
  minWidth,
  ...boxProps
}: ChipRadioButtonsProps) => (
  <ChipRadioButtonWrapper {...boxProps}>
    {children.map((child) =>
      cloneElement(child, {
        color,
        padding,
        minWidth,
        paddingVertical: mapResponsive(padding, (p) => p * 0.5),
        selected: value === child.props.value,
        onClick:
          child.props.onClick ||
          (() => {
            onChange(String(child.props.value))
          }),
      }),
    )}
  </ChipRadioButtonWrapper>
)

export const ChipGrid = ({
  children,
  grow,
  ...props
}: BoxProps & { grow?: boolean }) => (
  // MG: "Topics" under calendar
  <Box {...props}>
    <ChipGridWrapper grow={grow}>{children}</ChipGridWrapper>
  </Box>
)
