import { BoxLink, Rows, Display } from "../../design-system"
import React from "react"
import { shadows, themeColor } from "../theme"

export const HeroUnit: React.FC<{
  heading: string
  linkUrl: string
  imageUrl: string
}> = ({ heading, linkUrl, imageUrl }) => {
  return (
    <BoxLink
      style={{ textDecoration: 'none', color: 'white' }}
      to={linkUrl}
      showFrom="tablet"
      shadow={shadows.up}
      padding={3}
      bg={{ url: imageUrl }}
      color={themeColor.reverseContent}
      height="450px"
      alignSelf="center"
      width="100%"
    >
      <Rows height="100%" alignItems="flex-end" justifyContent="flex-end">
        <Rows spacing={1} padding={2} bg="#48CBB1" width="70%" offsetX={3}>
          <Display style={{ lineHeight: 0.9 }}>{heading}</Display>
        </Rows>
      </Rows>
    </BoxLink>
  )
}
