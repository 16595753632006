import * as yup from 'yup'
import { useEffect } from 'react'
import qs from 'query-string'
import { loadStripe } from '@stripe/stripe-js'
import { arrayify } from '../util'

export type DonationFields = yup.InferType<typeof donationSchema>

export const donationFields = {
  amount: yup.number().label('Contribution amount').required(),
  interval: yup.string().label('Contribution frequency'),
}

export const donationPersonalFields = {
  email: yup.string().label('Email').required().email(),
  firstName: yup.string().label('First name').required().min(2).max(150),
  lastName: yup.string().label('Last name').required().min(2).max(150),
  phone: yup.string().label('Phone number').min(2).max(150),
}

export const donationJoinFields = {
  consentToSubscription: yup.boolean(),
}

export const donationSchema = yup.object({
  ...donationFields,
  ...donationPersonalFields,
  ...donationJoinFields,
})

export const getStripeLineItems = async (
  amount: number,
  interval: string,
): Promise<
  {
    plan: string
    quanity: number
  }[]
> => {
  const res = await fetch(`${process.env.GATSBY_CMS_URL}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      variables: { amount, interval },
      query: `
      query ($amount: Float!, $interval: Interval) {
        stripeLineItems(amount: $amount, interval: $interval) {
          plan
          sku
          quantity
        }
      }
    `,
    }),
  })

  if (!res.ok) return []

  const {
    data: { stripeLineItems },
  } = await res.json()

  return stripeLineItems
}

const registerDonorRequest = async (variables: {
  email: string
  firstName: string
  lastName: string
  consentToSubscription: boolean
  extraData?: { key: string; value: string }[]
  tags?: string[]
}) => {
  const res = await fetch(`${process.env.GATSBY_CMS_URL}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      variables,
      query: `
        mutation SignupUser (
          $email: String!
          $firstName: String!
          $lastName: String!
          $consentToSubscription: Boolean!
          $extraData: [MergeTagItem!]
          $tags: [String!]
        ) {
          registerDonor(
            email: $email,
            firstName: $firstName
            lastName: $lastName
            consentToSubscription: $consentToSubscription
            extraData: $extraData
            tags: $tags
          ) {
            success
          }
      }
    `,
    }),
  })

  if (!res.ok) return false

  const {
    data: { success },
  } = await res.json()

  return success
}

const registerDonor = async () => {
  // Intercept parameters thrown back from Stripe
  // FIXME: This can be spoofed quite easily. Probably not a huge deal here, but note if copy-pasting elsewhere....
  const params = qs.parse(
    typeof window === `undefined`
      ? '{ donatedSuccessfully: 0 }'
      : window.location.search,
  )

  const formValuesFromURLQueryString = donationSchema.cast(params)
  const { donatedSuccessfully, mailchimpTags } = params
  const isAPostDonationSituation =
    donatedSuccessfully && donatedSuccessfully === '1'

  if (!isAPostDonationSituation) return

  // And then finish the registration flow
  const success = await registerDonorRequest({
    ...formValuesFromURLQueryString,
    extraData: [
      {
        key: 'PHONE',
        value: formValuesFromURLQueryString.phone,
      },
      {
        key: 'D_AMOUNT',
        value: formValuesFromURLQueryString.amount.toString(),
      },
      {
        key: 'D_INTERVAL',
        value: formValuesFromURLQueryString.interval || 'MONTH',
      },
    ],
    tags: arrayify(mailchimpTags),
  })

  console.info('Registered donor?', success)
}

export const useDonorSignup = () => {
  useEffect(() => {
    registerDonor()
  }, [])
}

interface UseSubmitDonationProps {
  redirectPath: string
  tags: string[]
  additionalQueryParameters?: {
    [key: string]: any
  }
  amounts: {
    value: number
    stripeId?: string
  }[]
  useProductId?: boolean
}

export const useSubmitDonation = ({
  redirectPath,
  tags,
  additionalQueryParameters = {},
  amounts,
  useProductId,
}: UseSubmitDonationProps) => {
  const donateOneOff = async (data: DonationFields) => {
    console.log('One off donation')
    const { email, amount, interval } = data
    const stripePromise = loadStripe(
      process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
      {
        apiVersion: '2016-07-06',
      },
    )

    // 1. Find the Stripe payment object
    let stripeLineItems = await getStripeLineItems(amount, interval)
    delete stripeLineItems[0].plan

    // 2. Redirect the user to Stripe Checkout
    const stripe = await stripePromise
    if (!stripe) throw new Error("Couldn't load our payment system, Stripe.")

    // 2.1. Set up data to the payment success and failure pages
    const host =
      typeof window === `undefined`
        ? 'http://localhost:8000'
        : window.location.protocol + '//' + window.location.host

    const successUrl = qs.stringifyUrl({
      url: `${host}${redirectPath}`,
      query: {
        ...data,
        ...additionalQueryParameters,
        mailchimpTags: tags,
        donatedSuccessfully: 1,
      } as any,
    })

    const cancelUrl = qs.stringifyUrl({
      url: `${host}${redirectPath}`,
      query: {
        ...data,
        // ...additionalQueryParameters,
        donatedSuccessfully: 0,
      } as any,
    })

    // 2.2. Execute the redirect

    const stripeParameters = {
      customerEmail: email,
      successUrl,
      cancelUrl,
      items: stripeLineItems,
    }

    console.log(stripeParameters)

    const { error } = await stripe.redirectToCheckout(stripeParameters)

    if (error) {
      console.error(error)
      return error
    }
  }

  return async (data: DonationFields) => {
    if (data.interval === 'ONE_TIME') {
      return donateOneOff(data)
    }

    const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

    const { email, amount, interval } = data

    // 1. Find the Stripe payment object
    let stripeLineItems

    if (useProductId) {
      const amountProductId = amounts.find((a) => a.value === amount).stripeId
      stripeLineItems = [{ quantity: 1, price: amountProductId }]
    } else {
      stripeLineItems = await getStripeLineItems(amount, interval)
      // TODO: Remove the workaround on the backend
      stripeLineItems = stripeLineItems.map((item) => ({
        price: item.plan,
        quantity: item.quantity,
      }))
    }

    // 2. Redirect the user to Stripe Checkout
    const stripe = await stripePromise
    if (!stripe) throw new Error("Couldn't load our payment system, Stripe.")

    // 2.1. Set up data to the payment success and failure pages
    const host =
      typeof window === `undefined`
        ? 'http://localhost:8000'
        : window.location.protocol + '//' + window.location.host

    const successUrl = qs.stringifyUrl({
      url: `${host}${redirectPath}`,
      query: {
        ...data,
        ...additionalQueryParameters,
        mailchimpTags: tags,
        donatedSuccessfully: 1,
      } as any,
    })

    const cancelUrl = qs.stringifyUrl({
      url: `${host}${redirectPath}`,
      query: {
        ...data,
        // ...additionalQueryParameters,
        donatedSuccessfully: 0,
      } as any,
    })

    // 2.2. Execute the redirect

    const stripeParameters = {
      customerEmail: email,
      successUrl,
      cancelUrl,
      lineItems: stripeLineItems,
      mode: useProductId ? 'payment' : 'subscription',
    }

    const { error } = await stripe.redirectToCheckout(stripeParameters)

    if (error) {
      console.error(error)
      return error
    }
  }
}
