import React, { ReactNode } from "react"
import { overrideable, Panel, ButtonAccessory, BoxProps } from "../../design-system"
import { ButtonLink, Button } from "../atoms"
import { ArrowRight } from "../graphics"

export interface ArrowLinkProps {
  href?: string
  children: ReactNode
}

export const ArrowLink = overrideable<ArrowLinkProps>(({ href, children, ...props }) => {
  return (
    <Panel spacing={3} {...props}>
      <ButtonLink to={href}>
        {children}
        
        <ButtonAccessory>
          <ArrowRight fill="#91E0D0" />
        </ButtonAccessory>
      </ButtonLink>
    </Panel>
  )
})

export const ArrowButton = overrideable<BoxProps>(({ children, ...props }) => {
  return (
    <Button {...props}>
      {children}
      
      <ButtonAccessory>
        <ArrowRight fill="#91E0D0" />
      </ButtonAccessory>
    </Button>
  )
})
