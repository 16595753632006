import {
  styled,
  Grid,
  FlexProps,
  GridProps,
  useTheme,
  overrideable,
  BoxProps,
} from '../../design-system'
import { spacingLevel, screens } from '../../design-system'
import { Link, FakeLink } from '../../design-system'
import { Box } from '../../design-system'
import {
  ComponentType,
  AnchorHTMLAttributes,
  Fragment,
  ReactElement,
  cloneElement,
  useMemo,
} from 'react'
import { colors, themeColor } from '../theme'
import React from 'react'
import { Field } from 'react-jeff'
import { Checkbox } from './field'

export const Button = overrideable<BoxProps & { variant?: string }>(
  styled(Box.withComponent('button'))`
    display: inline-flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    padding: ${props =>
        spacingLevel(props.paddingVertical || props.padding || 0)}px
      ${props => spacingLevel(props.paddingHorizontal || props.padding || 2)}px;
    padding-right: ${props =>
      spacingLevel(
        props.paddingRight || props.paddingHorizontal || props.padding || 2,
      )}px;
    line-height: 135%;
    font-weight: 700;
    font-family: 'NeueHaas', sans-serif;
    color: white;
    background-color: ${({ bg, theme }) =>
      bg || theme.color('control', 'contentAlt')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    border: none;
    outline: none;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    text-decoration: none;
    overflow: visible;
    white-space: normal;

    &:hover {
      background-color: ${({ disabled, theme }) =>
        disabled
          ? theme.color('controlDisabled', 'control')
          : theme.color('contentAlt')};
    }

    font-size: 26px;

    @media (mid-width: ${screens.desktop}px) {
      font-size: 32px;
    }

    ${({ theme, variant }) => theme.getStyle('button', variant)}
  `,
)

interface RadioButtonsProps extends GridProps {
  options: { value: any; label: string }[]
  field: Field<any>
  button?: ReactElement
}

export const RadioButtons = ({
  field,
  options,
  button = <Button type="button" />,
  ...props
}: RadioButtonsProps) => {
  const theme = useTheme()

  return (
    <Grid aria-role="radiogroup" spacing={2} {...props}>
      {options.map(opt =>
        cloneElement(
          button,
          {
            'aria-label': opt.label,
            'aria-role': 'radio',
            ...(field.props.value === opt.value
              ? { bg: theme.color('contentAlt') }
              : {}),
            onClick: () => field.props.onChange(opt.value),
          },
          opt.label,
        ),
      )}
    </Grid>
  )
}

interface CheckboxesProps extends GridProps {
  options: { id: string; label: string }[]
  field: Field<string[]>
  button?: ReactElement<{ field: Field<boolean> }>
}

export const Checkboxes = ({
  field,
  options,
  button = <Checkbox />,
  ...props
}: CheckboxesProps) => {
  const valueSet = useMemo(() => new Set(field.value), [field])

  return (
    <Grid
      aria-role="radiogroup"
      justifyContent="flex-start"
      alignItems="flex-start"
      justifyItems="flex-start"
      rowGap={1}
      columnGap={1}
      {...props}
    >
      {options.map(opt =>
        cloneElement(
          button,
          {
            label: opt.label,
            field: {
              props: {
                value: valueSet.has(opt.id),
                onChange: (value: boolean) => {
                  const next = new Set(valueSet)
                  if (value) {
                    next.add(opt.id)
                  } else {
                    next.delete(opt.id)
                  }

                  field.props.onChange(Array.from(next))
                },
              },
            },
          },
          opt.label,
        ),
      )}
    </Grid>
  )
}

export const ButtonLink = Button.withComponent(Link || FakeLink)
export const ButtonLinkExternal = Button.withComponent('a') as ComponentType<
  AnchorHTMLAttributes<{}>
>
