import './fragments'

import { graphql, Link } from 'gatsby'
import React, { FC, Fragment } from 'react'
import {
  CmsPageContext,
  useCmsPageContext,
  ArticlePageType,
  ListPageType,
  DigitalHubTheme,
  CoreTheme,
  TWT2020Theme,
  HomePageType,
  CategoryPageType,
  TWT2021Theme,
} from './context'
import { last } from 'lodash'
import { TWTCorePage, MainMenu } from '../twt-core'
import { SectionBlockElement } from './section-blocks'
import { CmsSiteData } from './__generated__/CmsSiteData'
import { CmsPageData } from './__generated__/CmsPageData'
import {
  Rows,
  Gutter,
  Columns,
  spacingLevel,
  Text,
  Theme,
} from '../design-system'
import { LogoGraveyard } from './__generated__/LogoGraveyard'
import { themeColor } from '../twt-core/theme'
import { PageFooter } from '../twt-core/organisms/page-footer'
import { LayoutCard } from '../twt-core/molecules'
import { BackArrow } from '../resource-bank/graphics/back-arrow'
import { Goudy } from '../assets/fonts'
import * as resourceHubTheme from '../resource-bank/theme'
import { PopupConfig } from '../twt-core/organisms/__generated__/PopupConfig'
import { Popups } from '../twt-core/organisms/popups'
import { fonts2020, theme2020, theme2020List } from '../twt-2020/theme'
import { LogoGraveyardElement } from './special-blocks'
import { Box } from '../design-system/atoms/box'
import { PageTitle } from '../twt-core/organisms/page-title'
import { css } from '@emotion/core'
import { fonts2021, theme2021 } from '../twt-2021/theme'

export const cmsPageFragment = graphql`
  fragment CmsPageData on WAGTAIL_PageInterface {
    title
    ancestors {
      url
      title
    }
    children {
      ...Subpage
      lastPublishedAt
    }
  }

  fragment CmsHomePageData on WAGTAIL_HomePage {
    children {
      ...MainMenuFragment
      ...SecondaryMenuFragment
    }
  }

  fragment CmsPageContent on WAGTAIL_HomePage {
    content {
      id
      ...SectionBlock
    }
  }

  fragment CmsSiteData on WAGTAIL {
    home: page(slug: "home") {
      ...CmsHomePageData
    }
    logoGraveyard: partners {
      ...LogoGraveyard
    }
    popups: page(slug: "popups") {
      ...PopupConfig
    }
  }
`

export interface PageDisplayProps extends CmsPageData {
  title: string
  showHeader?: boolean
  showFooter?: boolean
  showTitle?: boolean
  colour?: string
  type?: string
  theme?: string
  searchDescription?: string
  image?: {
    src: string
    srcSet: string
  }
}

export const CmsPage: FC<
  CmsSiteData & {
    page: PageDisplayProps
    logoGraveyard: LogoGraveyard
    popups: PopupConfig
    noTitle?: boolean
  }
> = ({ page, children, noTitle, ...site }) => {
  const getThemeProps = () => {
    if (page.theme === DigitalHubTheme) {
      return {
        fonts: [Goudy],
        titlePrefix: 'TWT Resource Hub',
        theme: new Theme(
          resourceHubTheme.themeColor,
          resourceHubTheme.themeFonts,
        ).override(),
      }
    }

    if (page.theme === TWT2020Theme) {
      return {
        fonts: fonts2020,
        titlePrefix: 'TWT20',
        theme:
          page.type === CategoryPageType || page.type === ListPageType
            ? theme2020List
            : theme2020,
      }
    }

    if (page.theme === TWT2021Theme) {
      return {
        fonts: fonts2021,
        titlePrefix: 'TWT21',
        theme: theme2021,
      }
    }

    return {}
  }

  const ref = React.useRef<HTMLDivElement>(null)

  const { showHeader = true, showFooter = true } = page

  return (
    <CmsPageContext.Provider
      value={{
        pageType: page.type,
        theme: page.theme,
        title: page.title,
        subpages: (page.children || []).filter(
          (child) => !!child.lastPublishedAt,
        ),
      }}
    >
      <TWTCorePage
        title={page.title}
        description={page.searchDescription}
        splashImage={page?.image?.src}
        showHeader={showHeader}
        showFooter={showFooter}
        {...getThemeProps()}
      >
        {showHeader && (
          <Box
            ref={ref}
            css={{
              width: '100%',
              position:
                page.theme === TWT2020Theme && page.type === HomePageType
                  ? 'absolute'
                  : undefined,
              '& + *': {
                paddingTop:
                  page.theme === TWT2020Theme && page.type === HomePageType
                    ? 147
                    : 0,
              },
            }}
          >
            <MainMenu menuItems={site.home?.children || []} />
          </Box>
        )}

        {!noTitle && <PageTitle {...page} />}

        {children}

        {showFooter && (
          <Rows bg={themeColor.bgReverse} color={themeColor.reverseContent}>
            <LogoGraveyardElement collections={site.logoGraveyard} />
            <PageFooter menuItems={site.home?.children || []} />
          </Rows>
        )}

        <Popups popups={site.popups} />
      </TWTCorePage>
    </CmsPageContext.Provider>
  )
}

const Img = Box.withComponent('img')

export const CmsPageContent: FC<{
  page: CmsPageData & PageDisplayProps & CmsPageContent
}> = ({ page }) => {
  const { pageType } = useCmsPageContext()
  const parent = last(page.ancestors)

  if (pageType === ArticlePageType && page.theme === DigitalHubTheme) {
    return (
      <Gutter>
        <Columns spacing={6} flip={{ mobile: true, tablet: false }}>
          <LayoutCard gutter={false}>
            <Rows
              minWidth={spacingLevel(7) + 'px'}
              spacing={3}
              marginBottom={{ mobile: 3, tablet: 0 }}
            >
              <Text variant="buttonTextSmall">
                <Link to={parent.url}>
                  <BackArrow /> {parent.title}
                </Link>
              </Text>
            </Rows>
          </LayoutCard>

          <Rows spacing={4} marginBottom={5}>
            {page.content.filter(Boolean).map((item, i) => (
              <SectionBlockElement key={item.id} block={item} index={i} />
            ))}
          </Rows>
        </Columns>
      </Gutter>
    )
  }

  if (pageType === ArticlePageType && page.theme === TWT2020Theme) {
    return (
      // <Gutter>
      <Columns
        // spacing={1}
        flip={{ mobile: true, tablet: false }}
        css={css`
          > * {
            flex-grow: 1;
            flex-basis: 0;
          }
        `}
      >
        <LayoutCard gutter={false}>
          <Img
            width="100%"
            height="479px"
            src={page?.image?.src}
            style={{ objectFit: 'cover' }}
          />
          <Text
            css={css`
              font-family: 'NeueHaas', sans-serif;
              margin-top: 0.5em !important;
              display: block;
            `}
          >
            <Link
              to={parent.url}
              css={css`
                color: black;
              `}
            >
              <BackArrow /> {parent.title}
            </Link>
          </Text>
        </LayoutCard>

        <Rows spacing={4} marginBottom={5}>
          {page.content.filter(Boolean).map((item, i) => (
            <SectionBlockElement key={item.id} block={item} index={i} />
          ))}
        </Rows>
      </Columns>
      // </Gutter>
    )
  }

  return (
    <Fragment>
      {(page.content || []).filter(Boolean).map((item, i) => (
        <SectionBlockElement key={item.id} block={item} index={i} />
      ))}
    </Fragment>
  )
}
