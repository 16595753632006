import { LayoutCard } from '../molecules'
import React, { FC, Fragment } from 'react'
import {
  NavLinks,
  Columns,
  Link,
  Panel,
  FullLogo,
  Flex,
  NavMenu,
} from '../../design-system'
import { graphql } from 'gatsby'
import { MainMenuFragment } from './__generated__/MainMenuFragment'
import {
  useCmsPageContext,
  TWT2020Theme,
  HomePageType,
} from '../../cms/context'
import { useStore } from 'outstated'
import { AuthStore } from '../../services'

export const mainMenuFragment = graphql`
  fragment MainMenuFragment on WAGTAIL_PageInterface {
    title
    url
    showInMenus
    ... on WAGTAIL_ExternalPage {
      externalUrl
    }
  }
`

export const MainMenu: FC<{
  menuItems: MainMenuFragment[]
}> = ({ menuItems, ...props }) => {
  const ctx = useCmsPageContext()
  const auth = useStore(AuthStore)

  return (
    <LayoutCard
      z={2}
      width="100%"
      bg={
        ctx.theme === TWT2020Theme
          ? ctx.pageType === HomePageType
            ? 'rgba(0,0,0,0)'
            : 'white'
          : undefined
      }
      padding={ctx.theme === TWT2020Theme ? 2 : undefined}
      {...props}
    >
      <NavLinks>
        <Columns alignItems="center" spacing={2}>
          <Link to="/">
            <Panel>
              <FullLogo scale={ctx.theme === TWT2020Theme ? 0.8 : undefined} />
            </Panel>
          </Link>

          <Flex />

          <NavMenu>
            {menuItems.map(
              (item) =>
                item.showInMenus && (
                  <Link key={item.id} to={item.externalUrl || item.url}>
                    {item.title}
                  </Link>
                ),
            )}
          </NavMenu>
        </Columns>
      </NavLinks>
    </LayoutCard>
  )
}
