import * as React from 'react'
import { graphql } from 'gatsby'
import { styled } from '../../design-system'
import { css } from '@emotion/core'
import { rgbToColorString, parseToRgb } from 'polished'
import { EventListBlock_blocks_WAGTAIL_PageChooserBlock_page_WAGTAIL_HomePage_events } from './__generated__/EventListBlock'
import {
  plural,
  formatFormation,
  formatDate,
  formatTime,
  formatTimezone,
  formatToColor,
  getUserTimezone,
} from '../../util'
import gql from 'graphql-tag'
import {
  ResponsiveImage,
  Box,
  Columns,
  Content,
  LinkedCard,
  Text,
  NoSSR,
} from '../../design-system'
import { Chip } from '../../twt-core/atoms'

type InputEvent = EventListBlock_blocks_WAGTAIL_PageChooserBlock_page_WAGTAIL_HomePage_events

export const EventCard: React.FC<{
  event: InputEvent
}> = ({ event }) => {
  // MG: This is the event card component which appears under each day in /twt21/calendar
  const color = event.parent?.colour || 'black'
  const rgb = parseToRgb(color)
  const format = event.format.length && event.format[0].name
  const isNotInEventTimezone =
    getUserTimezone() && getUserTimezone() !== event.timezone

  return (
    <LinkedCard
      paddingLeft={2}
      paddingTop={2}
      paddingBottom={3}
      justifyContent="flex-start"
      to={event.url}
      className="programme-cell"
      maxWidth="100%"
      width="100%"
      borderColor={formatToColor(format)}
      variant="event"
    >
      <Columns alignItems="center">
        <Box
          css={css`
            width: 80%;
            padding-right: 2rem;

            @media screen and (max-width: 768px) {
              width: 70%;
            }
          `}
        >
          <div>
            <Text variant="control">
              {formatFormation(event.formation)}
              {event.format.length ? ' | ' : ''}
              {(event.format || []).map((format) => format.name).join(', ')}
              {event.venue?.length > 0 && (
                <> 
                  {` | ${event.venue[0].name}`}
                </>
              )}
            </Text>
          </div>
          <div>
            <Text variant="eventCardHeading">{event.title}</Text>
          </div>

          {event.speakers?.length > 0 && (
            <Content variant="panelBody">
              With{' '}
              {plural(
                event.speakers?.map((p) => p.name),
                '&',
              )}
            </Content>
          )}
        </Box>
        {event.startTime && event.endTime && (
          <>
            <Box
              css={css`
                width: 20%;
                @media screen and (max-width: 768px) {
                  width: 50%;
                }
              `}
            >
              <Content
                variant="control"
                css={css`
                  padding-right: 4px;
                  @media screen and (min-width: 768px) {
                    display: none;
                  }
                `}
              >
                <br />
                {formatDate(event.startTime, event.timezone, true)}
                <br />
                {formatTime(event.startTime, event.timezone)}
                {' – '}
                {formatTime(event.endTime, event.timezone)}
              </Content>
              <Content
                variant="control"
                css={css`
                  @media screen and (max-width: 767px) {
                    display: none;
                  }
                `}
              >
                {formatDate(event.startTime, event.timezone)}
                <br />
                {formatTime(event.startTime, event.timezone)}
                {' – '}
                {formatTime(event.endTime, event.timezone)}
              </Content>
            </Box>

            <NoSSR>
              {isNotInEventTimezone && (
                <Content variant="control">
                  {formatDate(event.startTime, getUserTimezone())}
                  <br />
                  {formatTime(event.startTime, getUserTimezone())}–
                  {formatTime(event.endTime, getUserTimezone())}
                  <Text variant="control" color="grey">
                    {' '}
                    ({formatTimezone(event.startTime, getUserTimezone())})
                  </Text>
                </Content>
              )}
            </NoSSR>
          </>
        )}
      </Columns>
    </LinkedCard>
  )
}

export const eventCardFragments = graphql`
  fragment Event on WAGTAIL_Event {
    id
    slug
    url
    title
    parent {
      title
      url
      ... on WAGTAIL_HomePage {
        colour
      }
    }
    image {
      src
      ...WagtailImageFixed
    }
    format {
      name
    }
    startTime
    endTime
    timezone
    duration
    description
    organisations {
      ...Organisation
    }
    organisers {
      ...Person
    }
    speakers {
      ...Person
    }
    format {
      id
      name
    }
    topics {
      id
      name
    }
    formation
  }

  fragment Person on WAGTAIL_Person {
    id
    name
    strapline
    bio
    email
    twitter
    website
  }

  fragment Organisation on WAGTAIL_Organisation {
    id
    name
    description
    website
    logo {
      src
    }
  }
`

export const eventCardApolloFragments = gql`
  fragment EventCard on Event {
    id
    slug
    url
    title
    parent {
      title
      url
      ... on HomePage {
        colour
      }
    }
    image {
      src
    }
    format {
      name
    }
    startTime
    endTime
    duration
    formation
    description
    organisations {
      ...Organisation
    }
    organisers {
      ...Person
    }
    speakers {
      ...Person
    }
    format {
      id
      name
    }
    topics {
      id
      name
    }
  }

  fragment Person on Person {
    id
    name
    strapline
    bio
    email
    twitter
    website
  }

  fragment Organisation on Organisation {
    id
    name
    website
    logo {
      src
    }
  }
`
