import React, { FC, useRef } from 'react'
import { graphql } from 'gatsby'
import {
  Content,
  Panel,
  ButtonAccessory,
  BoxLink,
  Centered,
  Columns,
  Heading,
  Rows,
  Text,
  Grid,
  Box,
  overrideable,
} from '../design-system'
import {
  BlockComponent,
  extractLinkFromFragment,
  getStructField,
  getStructFieldOptional,
} from './cms.util'
import { ArrowRight, ArrowSmall } from '../twt-core/graphics'
import { DocumentGraphic } from '../resource-bank/graphics'
import { DonationForm } from '../twt-core/organisms/donation-form'
import { ButtonLink } from '../twt-core/atoms'
import { EventListBlock } from './__generated__/EventListBlock'
import { ParagraphBlock } from './__generated__/ParagraphBlock'
import { ArrowLinkBlock } from './__generated__/ArrowLinkBlock'
import { ImageBlock } from './__generated__/ImageBlock'
import { ImageBlockImage } from './__generated__/ImageBlockImage'
import { RawHTMLBlock } from './__generated__/RawHTMLBlock'
import {
  DocumentDownloadBlock,
  DocumentDownloadBlock_document,
} from './__generated__/DocumentDownloadBlock'
import { DonationBlock } from './__generated__/DonationBlock'
import { UniversalBlock } from './__generated__/UniversalBlock'
import { StreamBlock } from './stream-block'
import { DonationBlockRedirect } from './__generated__/DonationBlockRedirect'
import { DonationBlockAmounts } from './__generated__/DonationBlockAmounts'
import { useCmsPageContext, CmsPageContext } from './context'
import { basename } from 'path'
import { DonationBlockMessage } from './__generated__/DonationBlockMessage'
import { ArrowLink } from '../twt-core/molecules/arrow-link'
import { EventListBlockElement } from '../festival/organisms/event-list-block'
import { EventGroupListBlockElement } from '../festival/organisms/event-group-list-block'
import { EventSearchBarBlockElement } from '../festival/organisms/event-search-bar'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Spotlight } from '../twt-core'
import { useScrollSpy } from '../services'
import { SubpageGrid } from '../twt-core/organisms/subpage-grid';

// Paragraph

export const paragraphBlockFragment = graphql`
  fragment ParagraphBlock on WAGTAIL_RichTextBlock {
    rawValue
  }
`

export const ParagraphBlockElement: BlockComponent<ParagraphBlock> = ({
  block,
  ...props
}) => (
  <Content {...props}>
    <div dangerouslySetInnerHTML={{ __html: block.rawValue }} />
  </Content>
)

// ArrowLink

export const arrowLinkBlockFragment = graphql`
  fragment ArrowLinkBlock on WAGTAIL_StructBlock {
    blocks {
      field
      ...LinkFragment
      ... on WAGTAIL_TextBlock {
        value
      }
      ... on WAGTAIL_ImageChooserBlock {
        image {
          ...ImageFragment
        }
      }
    }
  }
`

export const ArrowLinkBlockElement: BlockComponent<ArrowLinkBlock> = ({
  block,
}) => (
  <ArrowLink href={extractLinkFromFragment(block)}>
    {block.blocks.find((b) => b.field === 'label').value}
  </ArrowLink>
)

// Image

export const imageBlockFragment = graphql`
  fragment ImageBlock on WAGTAIL_StructBlock {
    ...LinkFragment
    blocks {
      id
      field
      ...ImageBlockImage
    }
  }

  fragment ImageBlockImage on WAGTAIL_StructBlock {
    blocks {
      field
      ... on WAGTAIL_ImageChooserBlock {
        image {
          ...ImageFragment
        }
      }
    }
  }
`

const Img = Box.withComponent('img')

export const ImageBlockElement: BlockComponent<ImageBlock> = ({
  block,
  ...props
}) => {
  const link = extractLinkFromFragment(block)
  const image = getStructField<ImageBlockImage>(block, 'image')

  return (
    <BoxLink
      position="static"
      to={link}
      className="image"
      flexShrink={0}
      {...props}
    >
      <Img
        width="566px"
        height="479px"
        src={image.image.src}
        style={{ objectFit: 'cover' }}
      />
      {link && (
        <Centered position="absolute" width="100%" height="100%">
          <ArrowRight />
        </Centered>
      )}
    </BoxLink>
  )
}

// Raw HTML

export const rawHtmlBlockFragment = graphql`
  fragment RawHTMLBlock on WAGTAIL_RawHTMLBlock {
    rawValue
  }
`

export const RawHTMLBlockElement: BlockComponent<RawHTMLBlock> = ({
  block,
  ...props
}) => (
  <Box {...props}>
    <div dangerouslySetInnerHTML={{ __html: block.rawValue }} />
  </Box>
)

// File Download Block

export const documentDownloadBlockFragment = graphql`
  fragment DocumentDownloadBlock on WAGTAIL_DocumentChooserBlock {
    document {
      id
      title
      file
      fileSize
    }
  }
`

export const getDocumentUrl = ({ id, file }: DocumentDownloadBlock_document) =>
  [process.env.GATSBY_CMS_URL, 'documents', id, basename(file)].join('/')

export const DocumentDownloadBlockElement = overrideable<BlockComponent<DocumentDownloadBlock>>(({
  block,
  ...props
}) => (
  <BoxLink
    to={getDocumentUrl(block.document)}
    width="100%"
    flexShrink={1}
    border="1px solid #48CBB1"
    padding={3}
    {...props}
  >
    <Columns
      spacing={4}
      width="100%"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Content>
        <Text variant="cellTitle">Document</Text>
        <Heading level={3} variant="cell">
          {block.document.title}
        </Heading>
      </Content>
      <Rows spacing={1} alignItems="center">
        <DocumentGraphic />
        <Text variant="buttonTextSmall">Download</Text>
      </Rows>
    </Columns>
  </BoxLink>
))

// Donation

export const donationBlockFragment = graphql`
  fragment DonationBlock on WAGTAIL_StructBlock {
    blocks {
      id
      blockType
      field
      ...DonationBlockAmounts
      ...DonationBlockRedirect
      ...DonationBlockMessage
    }
  }

  fragment DonationBlockAmounts on WAGTAIL_ListBlock {
    items {
      ... on WAGTAIL_IntegerBlock {
        value
      }
    }
  }

  fragment DonationBlockMessage on WAGTAIL_TextBlock {
    value
  }

  fragment DonationBlockRedirect on WAGTAIL_PageChooserBlock {
    page {
      url
    }
  }
`

export const DonationBlockElement: BlockComponent<DonationBlock> = ({
  block,
  ...props
}) => {
  const redirect = getStructField<DonationBlockRedirect>(
    block,
    'success_redirect',
  ).page
  const prompt = getStructField<DonationBlockMessage>(block, 'prompt').value
  const mailingListMessage = getStructField<DonationBlockMessage>(
    block,
    'mailing_list_message',
  ).value
  const gdprMessage = getStructField<DonationBlockMessage>(
    block,
    'gdpr_message',
  ).value
  const buttonLabel = getStructField<DonationBlockMessage>(
    block,
    'button_label',
  ).value

  return (
    <DonationForm
      allowOneOff
      mailchimpTags={['Supporters Network']}
      amounts={
        getStructField<DonationBlockAmounts>(block, 'suggested_values').items ||
        []
      }
      redirectPath={redirect && redirect.url}
      {...{ prompt, mailingListMessage, gdprMessage, buttonLabel }}
      {...props}
    />
  )
}

// Subpage List

export const subpageListBlockFragment = graphql`
  fragment SubpageListBlock on WAGTAIL_StructBlock {
    blocks {
      id
      blockType
      field
      ... on WAGTAIL_TextBlock {
        value
      }
      ... on WAGTAIL_PageChooserBlock {
        page {
          id
          children {
            id
            url
            title
            ... on WAGTAIL_HomePage {
              colour
              image {
                aspectRatio
                src
                srcSet(sizes: [300, 400, 800, 1400])
                sizes
              }
            }
          }
        }
      }
    }
  }
`

const SubpageQuery = gql`
  query SubpageQuery($parent: Int!, $filter: [String!], $kind: String) {
    subpages(parent: $parent, filter: $filter, kind: $kind) {
      id
      url
      title
      colour
      image {
        width
        height
        src
        srcSet(sizes: [300, 400, 800, 1400])
      }
    }
  }
`

/**
 * Because of some annoyances around the way wagtail's schema works, we need to fetch the
 * subpages dynamically whenever we apply a custom filter to them. Here, we use a client-side
 * query if needed, otherwise doing a 'children' query
 */
const useSubpages = ({ context, itemType, filter, rootPage, skipQuery }) => {
  console.log(rootPage, itemType, filter)
  const subpageQuery = useQuery(SubpageQuery, {
    skip: skipQuery || !rootPage || (!filter && !itemType),
    variables: {
      kind: itemType || undefined,
      filter: filter ? [filter] : undefined,
      parent: rootPage?.page?.id,
    },
  })

  if (!rootPage) {
    return {
      items: context.subpages || [],
      loading: false,
    }
  }

  if (!filter && itemType === 'page') {
    return {
      items: rootPage.page?.children || [],
      loading: false,
    }
  }

  return {
    loading: !subpageQuery.data,
    items: subpageQuery.data?.subpages || [],
  }
}

const SubpageListBlockElement: BlockComponent = (props) => {
  const ref = useRef()
  const onScreenElement = useScrollSpy({
    refs: [ref],
    container: 'html',
    errorMargin: 0,
  })

  const context = useCmsPageContext()
  const displayMode = getStructField(props.block, 'display_mode').value
  const itemType = getStructFieldOptional(props.block, 'item_type')?.value
  const filter = getStructFieldOptional(props.block, 'filter')?.value
  const rootPage = getStructFieldOptional(props.block, 'root_page')

  const subpages = useSubpages({
    context,
    itemType,
    filter,
    rootPage,
    skipQuery: !onScreenElement,
  })

  console.log(subpages)

  if (displayMode === 'spotlight') {
    return (
      <Spotlight ref={ref} loading={subpages.loading} items={subpages.items} />
    )
  }

  return (
    <SubpageGrid ref={ref} loading={subpages.loading} items={subpages.items} />
  )
}

// Universal Block

export const universalBlockFragment = graphql`
  fragment UniversalBlock on WAGTAIL_StreamFieldInterface {
    field
    ...ParagraphBlock
    ...ArrowLinkBlock
    ...ImageBlock
    ...RawHTMLBlock
    ...DocumentDownloadBlock
    ...DonationBlock
    ...EventSearchBarBlock
    ...EventListBlock
    ...EventGroupListBlock
    ...SubpageListBlock
  }
`

export const UniversalBlockElement: BlockComponent<UniversalBlock> = (
  props,
) => (
  <StreamBlock
    blockTypes={{
      ParagraphBlock: ParagraphBlockElement,
      LinkBlock: ArrowLinkBlockElement,
      ImageBlock: ImageBlockElement,
      HtmlBlock: RawHTMLBlockElement,
      FileBlock: DocumentDownloadBlockElement,
      DonationBlock: DonationBlockElement,
      SubpageListBlock: SubpageListBlockElement,
      EventListBlock: EventListBlockElement,
      EventGroupListBlock: EventGroupListBlockElement,
      EventSearchBarBlock: EventSearchBarBlockElement,
    }}
    {...props}
  />
)
