import { FC, Fragment } from 'react'
import { graphql } from 'gatsby'
import React from 'react'
import { LogoGraveyard } from './__generated__/LogoGraveyard'
import {
  LogoGraveyardSection,
  LogoGraveyardItem,
} from '../twt-core/organisms/logo-graveyard'

export const logoGraveyardFragment = graphql`
  fragment LogoGraveyard on WAGTAIL_OrganisationCollection {
    id
    size
    showTitle
    title
    message
    organisations {
      id
      name
      website
      logo {
        file
        src
      }
    }
  }
`

export const LogoGraveyardElement: FC<{ collections: LogoGraveyard[] }> = ({
  collections,
}) => {
  if (!collections) {
    return null
  }

  return (
    <Fragment>
      {collections.map(c => (
        <LogoGraveyardSection
          key={c.id}
          size={c.size}
          title={c.showTitle ? c.title : undefined}
          message={c.message}
        >
          {c.organisations.map(o => (
            <LogoGraveyardItem
              key={o.id}
              logo={o.logo.src}
              name={o.name}
              website={o.website}
            />
          ))}
        </LogoGraveyardSection>
      ))}
    </Fragment>
  )
}
