import {
  overrideable,
  Panel,
  Heading,
  BoxProps,
  Box,
  Rows,
} from '../../design-system'
import {
  useCmsPageContext,
  ArticlePageType,
  DigitalHubTheme,
} from '../../cms/context'
import { LayoutCard } from '.'
import React from 'react'
import { doesNotThrow } from 'assert'

export interface SectionWrapperProps extends BoxProps {
  decorated?: boolean
  style?: string
  title?: string
  index?: number
  context?: string
  forceTitle?: boolean
}

export const SectionWrapper = overrideable<SectionWrapperProps>(
  ({
    decorated,
    style,
    context = 'article',
    index = 0,
    title,
    children,
    ...props
  }) => {
    const { pageType, theme } = useCmsPageContext()
    const first = index === 0

    const minLines = 2
    const maxLines = context === 'content' && style === 'article' ? 3 : 4

    // Vary the number of lines if we're showing them
    const lineCount = first
      ? maxLines
      : (title.length % (maxLines - minLines)) + minLines

    // Vary the offset the lines on the y axis. Pull up if we're first. Otherwise, randomise
    const lineOffsetY = first ? -4 : (title.length % 4) - 2

    let lineOffsetX = 0
    
    // Vary the offset the lines on the x axis. Pull up if we're first. Otherwise, randomise
    if (title && title.length) {
      lineOffsetX = -(title.length % 3)
    }

    const content = (
      <SectionContent title={title} index={index} type={pageType}>
        {children}
      </SectionContent>
    )

    if (pageType === ArticlePageType && theme === DigitalHubTheme) {
      return (
        <LayoutCard
          gutter={false}
          lineOffsetX={lineOffsetX}
          lineOffsetY={lineOffsetY}
          lineCount={lineCount}
          lines={decorated}
          {...props}
        >
          {content}
        </LayoutCard>
      )
    }

    return (
      <LayoutCard
        gutter={true}
        lineOffsetX={lineOffsetX}
        lineOffsetY={lineOffsetY}
        lineCount={lineCount}
        lines={decorated}
        {...props}
      >
        {content}
      </LayoutCard>
    )
  },
)

const SectionContent = ({ title, children, type, index }) => {
  if (type === 'article') {
    return (
      <Panel
        maxWidth="40em"
        spacing={3}
        alignSelf={index % 2 === 1 ? 'flex-end' : 'flex-start'}
      >
        {title && <SectionTitle>{title}</SectionTitle>}
        {children}
      </Panel>
    )
  }

  return <Panel spacing={3}>{children}</Panel>
}

export const SectionTitle = overrideable((props) => <Heading {...props} />)

export const Subsection = ({ style, ...props }: BoxProps) => {
  const { fullPage } = useCmsPageContext()
  return fullPage ? (
    <SectionWrapper {...props} />
  ) : (
    <Rows {...props} spacing={3} />
  )
}
