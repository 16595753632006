import React, { ReactNode } from 'react'
import { themeColor, themeFonts } from '../theme'
import {
  Page,
  PageProps,
  navMenuStore,
  Rows,
  styled,
  Theme,
} from '../../design-system'
import { useStore } from 'outstated'
import { useSpring } from 'react-spring'

import { NeueHaas } from '../../assets/fonts'
import { CrowdLarge } from '../graphics'
import { useDonorSignup } from '../../services/donate'

export interface TWTCorePageProps extends Partial<PageProps> {
  title?: string
  children?: ReactNode
  showHeader?: boolean
  showFooter?: boolean
}

const Wrapper = styled(Rows)`
  > :nth-last-child(3) {
    flex: 1;
  }
`

export const TWTCorePage = ({
  title,
  children,
  fonts = [],
  showHeader = true,
  showFooter = true,
  description = 'A month-long digital festival of politics, arts & culture.',
  splashImage = CrowdLarge.url,
  ...rest
}: TWTCorePageProps) => {
  const menu = useStore(navMenuStore)
  const darkened = useSpring({ opacity: menu.visible ? 0.24 : 1 })

  useDonorSignup()

  return (
    <Page
      title={title}
      description={description}
      splashImage={splashImage}
      theme={new Theme(themeColor, themeFonts)}
      fonts={[NeueHaas, ...fonts]}
      {...rest}
    >
      <Wrapper
        style={darkened}
        maxWidth="100%"
        alignItems="stretch"
        minHeight="100vh"
        overflow="hidden"
      >
        {children}
      </Wrapper>
    </Page>
  )
}
