import React, { FC } from 'react'
import { graphql } from 'gatsby'
import {
  LinkFragment,
  LinkFragment_blocks_WAGTAIL_PageChooserBlock,
  LinkFragment_blocks_WAGTAIL_URLBlock,
} from './__generated__/LinkFragment'

export type BlockComponent<Block = unknown> = FC<{ block: Block, index?: number }>

export const imageFragment = graphql`
  fragment ImageFragment on WAGTAIL_ImageObjectType {
    aspectRatio
    src
    srcSet(sizes: [300, 400, 800, 1400])
    sizes
  }
`

export const linkFragment = graphql`
fragment LinkFragment on WAGTAIL_StreamFieldInterface {
    field
    ... on WAGTAIL_URLBlock {
      value
    }
    ... on WAGTAIL_PageChooserBlock {
      page {
        url
      }
    }
  }
`

export function extractLinkFromFragment(fragment: LinkFragment) {
  const local = getStructField<LinkFragment_blocks_WAGTAIL_PageChooserBlock>(
    fragment,
    'local_link'
  )
  const global = getStructField<LinkFragment_blocks_WAGTAIL_URLBlock>(
    fragment,
    'web_link'
  )

  return local.page ? local.page.url : global.value
}

type Struct = { blocks: StructItem[]; __typename: string }
type StructItem = { field: string }

export function getStructField<T>(block: Struct, key: string): T {
  const field = block.blocks.find(b => b.field === key)
  if (!field) {
    console.warn('cannot find', key, 'in', block)
    throw Error('Cannot find field ' + key + ' in ' + block.__typename + '. Known keys: ' + block.blocks.map(b => b.field).join(', '))
  }

  return field as any
}

export function getStructFieldOptional<T>(block: Struct, key: string): T | undefined {
  const field = block.blocks.find(b => b.field === key)
  return field as any
}

export function isSchemaType<Expected extends string>(typename: string, expected: Expected): typename is Expected {
  return typename === expected || typename === 'WAGTAIL_' + expected
}

export const isBlockType = <B extends { __typename: string } = any>(
  block: { __typename: string },
  k: string
): block is B => {
  return block?.__typename === k || block?.__typename === k.replace('WAGTAIL_', '')
}

export const Debug = ({ children }) => <pre>{JSON.stringify(children, null, 2)}</pre>
