import React, { FC } from 'react'
import { SectionWrapperProps } from '../../twt-core/molecules/section'
import {
  Box,
  styled,
  Heading,
  spacingLevel,
  Panel,
  Rows,
} from '../../design-system'
import { useCmsPageContext, ListPageType } from '../../cms'
import { LayoutCard } from '../../twt-core'

export const SectionWrapper2020: FC<SectionWrapperProps> = ({
  title,
  style,
  index,
  children,
  context,
  ...props
}) => {
  const { pageType } = useCmsPageContext()
  if (style === 'article' || context === 'embed') {
    return (
      <LayoutCard {...props}>
        <Rows
          maxWidth="40em"
          spacing={3}
          alignSelf={index % 2 === 1 ? 'flex-end' : 'flex-start'}
        >
          {title && index !== 0 && <Heading>{title}</Heading>}
          {children}
        </Rows>
      </LayoutCard>
    )
  }

  const getTitle = () => {
    if (!title) {
      return null
    }

    if (context === 'section') {
      if (pageType === ListPageType) {
        return <ListSectionTitle>{title}</ListSectionTitle>
      }

      return <Heading>{title}</Heading>
    }

    return null
  }

  return (
    <Rows
      bg={getBg(style)}
      alignItems="stretch"
      padding={{ mobile: 2, desktop: 3 }}
      paddingVertical={{ mobile: 3, desktop: 4 }}
      spacing={3}
      {...props}
    >
      {title && getTitle()}
      {children}
    </Rows>
  )
}

export const SectionTitle2020 = (props) => {
  const { pageType } = useCmsPageContext()
  return pageType === ListPageType ? (
    <ListSectionTitle {...props} />
  ) : (
    <Heading {...props} />
  )
}

const ListSectionTitle = styled(Heading)`
  width: 100%;
  border-top: 1px solid black;
  padding-top: ${spacingLevel(1)}px;
  border-bottom: 1px solid black;
  margin-bottom: ${spacingLevel(3)}px;
`

const getBg = (style: string) => {
  if (style === 'promoted') {
    return 'bgAlt'
  }

  return 'bg'
}
