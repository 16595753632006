import * as React from 'react'
import { graphql } from 'gatsby'
import { EventListBlock_blocks_WAGTAIL_PageChooserBlock_page_WAGTAIL_HomePage_events } from './__generated__/EventListBlock'
import { Rows } from '../../design-system/atoms/flex'
import { Text } from '../../design-system/atoms/text'
import { BoxLink, ContentWrapper } from '../atoms/box'
import { styled } from '../../design-system/styled'
import { Box, ResponsiveImage } from '../../design-system'
import gql from 'graphql-tag';

type InputEvent = EventListBlock_blocks_WAGTAIL_PageChooserBlock_page_WAGTAIL_HomePage_events

export const EventGroupCardBox = styled(BoxLink)`
  ${({ colour }) =>
    colour
      ? {
          background: colour,
        }
      : ``};
`

export const EventGroupCard: React.FC<{
  eventGroup: InputEvent
}> = ({ eventGroup }) => {
  return (
    <EventGroupCardBox to={eventGroup.url} colour={eventGroup.colour}>
      <ContentWrapper>
        <Rows flexGrow={1} spacing={2}>
          <Rows alignItems="center" bg="white" padding={2}>
            <ResponsiveImage
              width={{ mobile: '135px', tablet: '249px' }}
              height={{ mobile: '135px', tablet: '249px' }}
              imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
              image={eventGroup.image}
            />
          </Rows>

          <Text variant="heading">{eventGroup.title}</Text>
        </Rows>
      </ContentWrapper>
    </EventGroupCardBox>
  )
}

export const eventGroupCardFragments = graphql`
  fragment EventGroup on WAGTAIL_HomePage {
    __typename
    id
    slug
    title
    url
    image {
      src
    }
    colour
    earliestTime
    latestTime
    events {
      title
    }
  }
`

export const eventGroupCardApolloFragments = gql`
  fragment EventGroup on HomePage {
    __typename
    id
    slug
    title
    url
    image {
      src
    }
    colour
    earliestTime
    latestTime
    events {
      title
    }
  }
`
