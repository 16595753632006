import React, { ReactNode, FC } from "react"
import { overrideable, Panel, ButtonAccessory, BoxProps } from "../../design-system"
import { ArrowLinkProps } from "../../twt-core/molecules/arrow-link"
import { ButtonLink, Button } from "../../twt-core"
import { css } from '@emotion/core';

const buttonCss = props => css`
  color: ${props.colors.indigo};
  border-color: ${props.colors.indigo};
  font-size: 1.5em;

  &:hover {
    color: black;
  }
`

export const ArrowLink2020: FC<ArrowLinkProps> = ({ href, children, ...props }) => (
  <ButtonLink paddingVertical={1} paddingHorizontal={2} to={href} css={buttonCss}>
    {children} ➔
  </ButtonLink>
)

export const ArrowButton2020: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Button paddingVertical={1} paddingHorizontal={2} css={buttonCss} {...props}>
      {children} ➔
    </Button>
  )
}
