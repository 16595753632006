import Fuse from 'fuse.js'
import { useMemo, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { EventSearchResult } from './organisms/__generated__/EventSearchResult'

type SearchableEvent = Required<Pick<EventSearchResult, 'title'>> &
  Partial<EventSearchResult>

type EventSearchOptions<T> = Fuse.IFuseOptions<T> & {
  initialQuery?: string
  initialResults?: Fuse.FuseResult<T>[]
  blankQueryPolicy?: 'show-all' | 'show-none'
}

export const useEventSearch = <
  InputEvent extends SearchableEvent = SearchableEvent,
  T extends InputEvent = InputEvent
>(
  events: T[],
  {
    initialQuery = '',
    initialResults = [],
    blankQueryPolicy = 'show-none',
    ...fuseOverrideOptions
  }: EventSearchOptions<T> = {},
) => {
  const fuseOptions = useMemo(
    () => ({
      includeMatches: true,
      threshold: 0.8,
      minMatchCharLength: 4,
      ignoreLocation: true, // so that keywords in the description can be found
      keys: [
        {
          name: 'title',
          weight: 2,
        },
        {
          name: 'topics.name',
          weight: 1.25,
        },
        {
          name: 'parent.title',
          weight: 1.25,
        },
        {
          name: 'descriptionPlaintext',
          weight: 0.75,
        },
        {
          name: 'speakers.name',
          weight: 0.5,
        },
        {
          name: 'organisations.name',
          weight: 0.3,
        },
        {
          name: 'organisers.name',
          weight: 0.3,
        },
        {
          name: 'format.name',
          weight: 0.3,
        },
      ],
      ...fuseOverrideOptions,
    }),
    [fuseOverrideOptions],
  )
  const searchEngine = useMemo(() => new Fuse<T>(events, fuseOptions), [events])

  const [query, setQuery] = useState<string>(initialQuery)
  const [results, setResults] = useState<Fuse.FuseResult<InputEvent>[]>(
    initialResults || searchEngine.search(query),
  )

  useEffect(() => {
    setResults(() => {
      if (!query || query?.length <= 0) {
        switch (blankQueryPolicy) {
          case 'show-all':
            return events.map((item) => ({ item, refIndex: NaN }))
          case 'show-none':
          default:
            return []
        }
      }
      return searchEngine.search(query)
    })
  }, [query, events, setResults, blankQueryPolicy])

  return { searchEngine, setQuery, query, results, setResults }
}

export const eventSearchCardFragments = graphql`
  fragment EventSearchResult on WAGTAIL_Event {
    id
    slug
    url
    title
    parent {
      title
    }
    startTime
    endTime
    duration
    descriptionPlaintext
    organisations {
      name
    }
    organisers {
      name
    }
    speakers {
      name
    }
    topics {
      name
    }
    format {
      name
    }
    formation
    venue {
      name
    }
  }
`
