import {
  Themed,
  FlexProps,
  BoxLink,
  Columns,
  styled,
  spacingLevel,
} from '../../design-system'
import { Button } from './button'
import { css } from '@emotion/core'

interface ChipProps extends FlexProps {
  variant?: string
}

const chipMixin = ({ theme, color, variant }: Themed<ChipProps>) => css`
  background-color ${theme.color(color)};
  color: white;
  text-align: center;
  padding: ${spacingLevel(1)}px;

  ${theme.getStyle('chip', variant)}
  ${theme.font('control')}
`

export const ChipButton = styled(Button)<ChipProps>`
  ${chipMixin}
`

export const ChipLink = styled(BoxLink)<ChipProps>`
  ${chipMixin}
`

export const Chip = styled(Columns)<ChipProps>`
  ${chipMixin}
`
