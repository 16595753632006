import { LayoutCard } from '../molecules'
import React, { Fragment } from 'react'
import {
  NavLinks,
  Columns,
  Link,
  Panel,
  FullLogo,
  Flex,
  NavMenu,
  Rows,
  MobileNavLinks,
  LinkExternal,
  TwitterIcon,
  FacebookIcon,
  EmailLink,
  LinkInternal,
  NavLinksSmall,
  Box,
  Logo,
  Bold,
} from '../../design-system'
import { ComponentWithFragment } from '../../types'
import { graphql } from 'gatsby'
import { isSchemaType } from '../../cms/cms.util'
import { themeColor } from '../theme'
import { SecondaryMenuFragment } from './__generated__/SecondaryMenuFragment'

export const secondaryMenuFragment = graphql`
  fragment SecondaryMenuFragment on WAGTAIL_PageInterface {
    title
    url
    ... on WAGTAIL_HomePage {
      showInSecondaryMenu
    }
  }
`

export const PageFooter: ComponentWithFragment<{
  menuItems: SecondaryMenuFragment[]
}> = ({ menuItems }) => {
  const links = menuItems.map(item => {
    if (
      isSchemaType(item.__typename, 'WAGTAIL_HomePage') &&
      item.showInSecondaryMenu
    ) {
      return <LinkInternal to={item.url}>{item.title}</LinkInternal>
    }
  })

  return (
    <Fragment>
      <Rows showFrom="tablet" padding={{ mobile: 2, desktop: 4 }}>
        <NavLinksSmall>
          <Columns wrap spacing={4}>
            <Box>
              <LinkInternal to="/">
                <Logo fill={themeColor.reverseContent} />
              </LinkInternal>
            </Box>
            <Rows flex={3}>{links}</Rows>
            <Rows>
              <EmailLink address="info@theworldtransformed.org" />
              <LinkExternal href="https://twitter.com/TWT_NOW">
                <Bold>Twitter</Bold>
              </LinkExternal>
              <LinkExternal href="https://www.facebook.com/TWTNow/">
                <Bold>Facebook</Bold>
              </LinkExternal>
            </Rows>
          </Columns>
        </NavLinksSmall>
      </Rows>

      <Rows
        showUntil="tablet"
        padding={{ mobile: 2, desktop: 4 }}
        marginBottom={3}
        spacing={1}
      >
        {links}
        <Columns wrap spacing={2} flipSpacing={2} marginTop={2} alignItems="center">
          <LinkExternal href="https://twitter.com/TWT_NOW">
            <TwitterIcon />
          </LinkExternal>
          <LinkExternal href="https://www.facebook.com/TWTNow/">
            <FacebookIcon />
          </LinkExternal>
          <Flex />
          <EmailLink address="info@theworldtransformed.org" />
        </Columns>
      </Rows>
    </Fragment>
  )
}
