import React from 'react'
import { styled, SvgGraphic, BoxProps } from '../../design-system'

export interface LineProps extends BoxProps {}

const LineGraphic = styled(SvgGraphic)`
  position: absolute;
  z-index: 1;
`

export const PinkLine = (props: LineProps) => (
  <LineGraphic viewBox="0 0 361 385" fill="none" {...props}>
    <path
      d="M657.7 3.8L3.4 381.6"
      stroke="#FF91AD"
      strokeWidth={5.669}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </LineGraphic>
)

export const GreenLine = (props: LineProps) => (
  <LineGraphic viewBox="0 0 660 384" fill="none" {...props}>
    <path
      d="M2.8 3.3L657.1 381"
      stroke="#48CBB1"
      strokeWidth={5.669}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </LineGraphic>
)
