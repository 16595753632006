import { FC } from 'react'
import React from 'react'
import {
  Rows,
  Content,
  Grid,
  BoxLink,
  ImageView,
  Heading,
} from '../../design-system'

interface LogoGraveyardSectionProps {
  message?: string
  title?: string
  size: number
}

const GRID_SIZES = {
  0: { columnSize: 50, maxColumnSize: 150 },
  1: { columnSize: 100, maxColumnSize: 200 },
  2: { columnSize: 200 },
}

export const LogoGraveyardSection: FC<LogoGraveyardSectionProps> = ({
  message,
  title,
  size,
  children,
}) => {
  return (
    <Rows spacing={2} padding={{ mobile: 2, desktop: 4 }}>
      {title && (
        <Heading level={2} variant="headingSmall">
          {title}
        </Heading>
      )}
      {message && (
        <Content variant="small" css={{ color: 'white', fontWeight: 'normal' }}>
          {message}
        </Content>
      )}

      <Grid
        justifyItems="center"
        justifyContent="center"
        gap={3}
        {...(GRID_SIZES[size] || GRID_SIZES[1])}
      >
        {children}
      </Grid>
    </Rows>
  )
}

interface LogoGraveyardItemProps {
  name: string
  website: string
  logo: string
}

export const LogoGraveyardItem: FC<LogoGraveyardItemProps> = ({
  logo,
  name,
  website,
}) => (
  <BoxLink to={website}>
    <ImageView src={logo} alt={name} maxHeight="10em" />
  </BoxLink>
)
