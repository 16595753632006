import { FC } from 'react'
import { overrideable } from '../../design-system'

export interface SpotlightProps {
  items: SpotlightItem[]
  loading?: boolean
}

export interface SpotlightItem {
  id: string
  title: string
  url: string
  image?: any
  colour?: string
}

/** Not implemented in core yet */
export const Spotlight = overrideable<SpotlightProps>(() => null)
