import { css } from '@emotion/core'
import React, { FC, ReactNode, Fragment } from "react";
import { Panel, Heading, Columns } from "../../design-system";

interface FeatureBlockProps {
  title?: string
  content: ReactNode
  contextualContent: ReactNode
  style?: string
  index?: number
}

export const FeatureCard2020: FC<FeatureBlockProps> = ({ content, contextualContent, title, style, index = 0, ...props }) => {
  const contentJsx = (
    <Panel minWidth={{ desktop: "30em" }} flex={1} spacing={3}>
      {title && <Heading level={2} variant="display">{title}</Heading>}
      {content}
    </Panel>
  )

  const contextualContentJsx = (
    <Panel css={{
      'img': {
        width: '100%',
        height: 'auto'
      }
    }} width="50%">
      {contextualContent}
    </Panel>
  )

  const getContent = () => (
    <Fragment>
      {contentJsx}
      {contextualContentJsx}
    </Fragment>
  )

  return (
    <Columns spacing flip={{ mobile: true, tablet: false }} justifyContent="center">
      {getContent()}
    </Columns>
  )
}
