import React, { FC, forwardRef } from 'react'
import {
  Box,
  Carousel,
  ResponsiveImage,
  Heading,
  Columns,
} from '../../design-system'
import { SpotlightProps } from '../../twt-core'
import { EventGroupCard } from '../../festival/molecules/event-group-card'
import { LoadingOverlay } from '../../design-system/atoms/loading-spinner'

export const Spotlight2020: FC<SpotlightProps> = forwardRef(
  ({ items, loading }, ref) => {
    return (
      <Columns
        ref={ref}
        spacing={2}
        overflowX="auto"
        overflowY="hidden"
        height={{ mobile: '450px', desktop: '483px' }}
      >
        <LoadingOverlay loading={loading} />
        {items.map((item) => (
          <EventGroupCard key={item.id} height="100%" eventGroup={item} />
          // <Columns width={{ desktop: '957px' }} maxWidth="95vw" key={item.id}>
          //   {item.image && (
          //     <ResponsiveImage image={item.image} width="50%" height="457px" />
          //   )}

          //   <Box width="50%">
          //     <Heading>{item.title}</Heading>
          //   </Box>
          // </Columns>
        ))}
      </Columns>
    )
  },
)
