import React from 'react'
import { BlockComponent } from './cms.util'

export interface StreamBlockProps {
  blockTypes: Record<string, BlockComponent>
  block: { field: string }
}

export function StreamBlock<T>({ block, blockTypes, ...props }: StreamBlockProps & T) {
  if (!block) {
    return null
  }

  const BlockComponent = blockTypes[block.field]
  if (!BlockComponent) {
    console.warn('Unknown block type:', block.field)
    return null
  }

  return <BlockComponent block={block} {...props} />
}
