import React from 'react'
import {
  Content,
  Panel,
  ButtonAccessory,
  BoxLink,
  Centered,
  Columns,
  Heading,
  Rows,
  Text,
  Grid,
  Box,
  overrideable,
} from '../../design-system'
import { BlockComponent } from '../../cms/cms.util';
import { DocumentDownloadBlock } from '../../cms/__generated__/DocumentDownloadBlock';
import { getDocumentUrl } from '../../cms/universal-blocks';
import { css } from '@emotion/core';

export const DocumentDownloadBlockElement2020: BlockComponent<DocumentDownloadBlock> = ({
  block,
  ...props
}) => (
  <BoxLink
    to={getDocumentUrl(block.document)}
    width="100%"
    flexShrink={1}
    border="1px solid black"
    padding={2}
    {...props}
  >
    <Columns
      spacing={0}
      width="100%"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Content>
        <Text variant="cellTitle" color='indigo'>Document</Text>
        <Heading level={3} variant="cell">
          {block.document.title}
        </Heading>
      </Content>
      <Rows spacing={1} alignItems="center">
        <DocumentGraphic width={80} />
        <Text variant="buttonTextSmall" color='indigo'>Download</Text>
      </Rows>
    </Columns>
  </BoxLink>
)

import { SvgGraphicProps, SvgGraphic } from '../../design-system'

export const DocumentGraphic = (props: SvgGraphicProps) => (
  <SvgGraphic viewBox="0 0 83 101" fill="none" {...props}>
    <path d="M63.9512 3.73138V18.6945H78.9134L63.9512 3.73138Z" fill="#784BFB"/>
    <path d="M59.4071 23.2382V0.519989H0.339844V100.48H82.1253V23.2382H59.4071ZM25.677 53.8301L38.9607 67.1149V28.403H43.5044V67.1149L56.7892 53.8301L60.0017 57.0426L41.2326 75.8117L22.4646 57.0426L25.677 53.8301ZM70.7662 89.1209H11.6989V64.1309H16.2426V84.5773H66.2226V64.1309H70.7662V89.1209Z" fill="#784BFB"/>
  </SvgGraphic>
)
