import qs from 'query-string'
import React, { useState } from 'react'
import {
  CaptionSmall,
  Form,
  FormField,
  FormSubmit,
  Grid,
  Link,
  NumberFieldType,
} from '../../design-system'
import { useFormSchema } from '../../services'
import { donationSchema, useSubmitDonation } from '../../services/donate'
import { Button, Checkbox, Control, Field, RadioButtons } from '../atoms'
import { ArrowButton } from '../molecules/arrow-link'

export const DonationForm: React.FC<{
  amounts: {
    value: number
    stripeId?: string
  }[]
  redirectPath?: string
  prompt: string
  mailingListMessage: string
  gdprMessage: string
  buttonLabel: string
  additionalQueryParameters?: {
    [key: string]: any
  }
  mailchimpTags: string[]
  initialData?: {}
  buttonVariant?: string
  noPhoneNumber?: boolean
  hideGDPRConsent?: boolean
  hideMailingList?: boolean
  hidePersonalDetails?: boolean
  hideEmailAddress?: boolean
  submitButtonProps
  selectAmount?: { value: number }
  useProductId?: boolean
  allowUserAmount?: boolean
  allowOneOff?: boolean
}> = ({
  amounts = [],
  redirectPath,
  submitButtonProps,
  prompt,
  mailingListMessage,
  gdprMessage,
  buttonLabel,
  additionalQueryParameters,
  initialData,
  mailchimpTags,
  noPhoneNumber = false,
  hideGDPRConsent = false,
  hideMailingList = false,
  hidePersonalDetails = false,
  hideEmailAddress = false,
  selectAmount,
  useProductId = false,
  allowUserAmount = true,
  allowOneOff = false,
}) => {
  const handleDonate = useSubmitDonation({
    redirectPath: redirectPath || '',
    additionalQueryParameters,
    tags: mailchimpTags,
    amounts,
    useProductId,
  })

  // Preload any query strings from the URL into the form too
  const form = useFormSchema({
    schema: donationSchema,
    initialData: {
      interval: 'MONTH',
      ...initialData,
      ...qs.parse(typeof window === `undefined` ? '' : window.location.search),
    },
    defaultValues: {
      amount: selectAmount
        ? selectAmount.value
        : amounts?.[1]
        ? amounts[1].value
        : amounts?.[0]?.value,
    },
    onSubmit: handleDonate,
  })

  if (allowOneOff) {
    prompt =
      form.values.interval === 'MONTH'
        ? 'Choose your monthly donation'
        : 'Choose your donation'
  }

  return (
    <Form {...form.props} width="100%" alignItems="flex-start" spacing={2}>
      {/* Required for Stripe Checkout */}
      <script src="https://js.stripe.com/v3/" />

      {allowOneOff && (
        <Control
          label="How often do you want to donate?"
          width="100%"
          labelStyle={{ fontSize: 18 }}
        >
          <RadioButtons
            button={
              <Button
                style={{ justifyContent: 'center' }}
                type="button"
                width="100%"
                height="100%"
                marginTop={2}
                paddingVertical={2}
                paddingHorizontal={3}
              />
            }
            field={form.fields.interval}
            options={[
              { label: 'Every month', value: 'MONTH' },
              { label: 'Just once', value: 'ONE_TIME' },
            ]}
            width="100%"
            columnSize={100}
            maxColumnSize={200}
            justifyContent="flex-start"
          />
        </Control>
      )}

      <Control label={prompt} width="100%" labelStyle={{ fontSize: 18 }}>
        <RadioButtons
          button={
            <Button
              style={{ justifyContent: 'center' }}
              type="button"
              width="100%"
              height="100%"
              marginTop={2}
              paddingVertical={2}
              paddingHorizontal={3}
            />
          }
          field={form.fields.amount}
          options={amounts.map((a) => ({
            label: <span>{'£' + a.value}</span>,
            value: a.value,
          }))}
          width="100%"
          columnSize={100}
          maxColumnSize={200}
          justifyContent="flex-start"
        />
      </Control>

      <Grid
        fillMode="auto-fill"
        justifyContent="start"
        alignContent="start"
        justifyItems="stretch"
        alignItems="stretch"
        columnSize={280}
        gap={3}
        rowGap={1}
        width="100%"
      >
        {allowUserAmount && (
          <Control label="Amount">
            <FormField
              prefix="£"
              id="amount"
              autoComplete="off"
              valueType={NumberFieldType}
              wrapper={Field}
              field={form.fields.amount}
            />
          </Control>
        )}

        {!hidePersonalDetails ? (
          <>
            <Control label="First name">
              <FormField
                autoComplete="given-name"
                wrapper={Field}
                field={form.fields.firstName}
              />
            </Control>

            <Control label="Last name">
              <FormField
                autoComplete="family-name"
                wrapper={Field}
                field={form.fields.lastName}
              />
            </Control>

            {!hideEmailAddress ? (
              <>
                <Control label="Email address">
                  <FormField
                    autoComplete="email"
                    type="email"
                    wrapper={Field}
                    field={form.fields.email}
                  />
                </Control>
              </>
            ) : (
              <>
                <input
                  type="hidden"
                  name="email"
                  value={form.fields.email.value}
                />
              </>
            )}
          </>
        ) : (
          <>
            <input
              type="hidden"
              name="given-name"
              value={form.fields.firstName.value}
            />
            <input
              type="hidden"
              name="family-name"
              value={form.fields.lastName.value}
            />
            <input type="hidden" name="email" value={form.fields.email.value} />
          </>
        )}

        {!noPhoneNumber && (
          <Control label="Phone number">
            <FormField
              autoComplete="tel"
              type="phone"
              wrapper={Field}
              field={form.fields.phone}
            />
          </Control>
        )}
      </Grid>

      {!hideMailingList && (
        <Checkbox
          field={form.fields.consentToSubscription}
          label={mailingListMessage}
        />
      )}

      {!hideGDPRConsent && (
        <CaptionSmall>
          {gdprMessage} for more details, see our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </CaptionSmall>
      )}

      <FormSubmit>
        <ArrowButton
          {...submitButtonProps}
          disabled={!form.valid}
          type="submit"
        >
          {buttonLabel}
        </ArrowButton>
      </FormSubmit>
    </Form>
  )
}
