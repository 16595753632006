import React from 'react'
import { SvgGraphicProps, SvgGraphic } from '../../design-system'

export const BackArrow = (props: SvgGraphicProps) => (
  <SvgGraphic viewBox="0 0 14 11" fill="none" {...props}>
    <path
      d="M1.034 6.702h11.959V.553"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M1 6.714l4.68-2.888M1 6.714l4.68 2.888"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </SvgGraphic>
)
