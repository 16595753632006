import React from 'react'

import { PageDisplayProps, HomePageType, CoreTheme } from '../../cms'
import { overrideable, Display } from '../../design-system'
import { LayoutCard } from '../molecules'
import { themeColor } from '../theme'

export const PageTitle = overrideable<PageDisplayProps>(
  ({ type, theme, title }) => {
    if (type !== HomePageType) {
      return (
        <LayoutCard marginVertical={0}>
          <Display
            underline={theme === CoreTheme ? themeColor.subtleAlt : undefined}
          >
            {title}
          </Display>
        </LayoutCard>
      )
    }

    return null
  },
)
