import React, { FC, forwardRef } from 'react'
import { overrideable, LoadingOverlay } from '../../design-system'
import {
  Content,
  Panel,
  ButtonAccessory,
  BoxLink,
  Centered,
  Columns,
  Heading,
  Rows,
  Text,
  Grid,
  Box,
} from '../../design-system'
import { ArrowSmall } from '../graphics'

export interface SubpageGridProps {
  items: SubpageGridItem[]
  loading?: boolean
}

export interface SubpageGridItem {
  id: string
  title: string
  url: string
  image?: any
  colour?: string
}

export const SubpageGrid = overrideable<SubpageGridProps>(({ items, loading, ...props }) => {
  return (
    <Grid width="100%" columnSize={320} gap={2} {...props}>
      <LoadingOverlay loading={loading} />
      {items?.map((s) => (
        <BoxLink
          key={s.id}
          height="220px"
          width="100%"
          to={s.url}
          bg={{
            url: s.image
              ? s.image.src
              : require('../graphics/crowdlarge.jpg'),
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        >
          <Columns
            bg="#C1ECE5"
            bottom={0}
            left={0}
            width="100%"
            position="absolute"
            color="#001426"
            padding={1}
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text lineClamp={2} variant="gridCell">
              {s.title}
            </Text>

            <ArrowSmall />
          </Columns>
        </BoxLink>
      ))}
    </Grid>
  )
})
