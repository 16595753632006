import 'react-responsive-modal/styles.css'

import React, { useState, FC, ReactNode, ComponentType } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useStore } from 'outstated'
import { Modal } from 'react-responsive-modal'

import {
  Text,
  FormField,
  Form,
  FormSubmit,
  Columns,
  NoSSR,
  FacebookIcon,
  EmailIcon,
  BoxProps,
  Centered,
  Box,
} from '../../design-system'
import { LayoutCard } from '../molecules'
import { AuthStore, useFormSchema } from '../../services'
import { Field, Control, Button } from '../atoms'
import { useForm, useField } from 'react-jeff'
import { object, string } from 'yup'
import { createPortal } from 'react-dom'
import { tuple } from '../../util'
import { noop } from 'lodash'
import styled from '@emotion/styled'

type LoginState = 'login' | 'email' | 'waitLink'

export const ProtectedContent: FC<{ loggedOutContent?: ReactNode }> = ({
  loggedOutContent = null,
  children,
}) => {
  const auth = useStore(AuthStore)
  if (!auth.authenticated) {
    return <NoSSR>{loggedOutContent}</NoSSR>
  }

  return <NoSSR>{children}</NoSSR>
}

interface LoginScreenProps {
  messages?: Partial<Record<LoginState, ReactNode>>
  render?: Partial<Record<LoginState, React.FC>>
  onCompleted: () => void
  ContentWrapper?: ComponentType
}

const DEBUG_INITIAL_STATE =
  typeof sessionStorage !== 'undefined' &&
  (sessionStorage.getItem('twt.login.state.debug') as LoginState)

export const LoginScreen: FC<LoginScreenProps> = ({
  onCompleted,
  messages = {},
  ContentWrapper = LayoutCard,
  render,
}) => {
  const auth = useStore(AuthStore)
  const [state, setState] = useState<LoginState>(DEBUG_INITIAL_STATE || 'email')
  const tokenField = useField({
    defaultValue: '',
  })
  const [email, setEmail] = useState<string>()

  const handleAuthenticated = () => {
    onCompleted()
  }

  let form

  if (state === 'login') {
    const handleLoginWithFacebook = async (res: { accessToken: string }) => {
      await auth.handleLoginWithFacebook(res)
      handleAuthenticated()
    }

    form = (
      <>
        <FacebookLogin
          appId={process.env.GATSBY_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          callback={handleLoginWithFacebook}
          render={(renderProps) => (
            <LoginMethodButton variant="facebook" {...renderProps}>
              Login with Facebook ➔
            </LoginMethodButton>
          )}
        />
        <LoginMethodButton variant="email" onClick={() => setState('email')}>
          Login with Email ➔
        </LoginMethodButton>
      </>
    )

    return render[state] ? (
      render[state](form)
    ) : (
      <>
        {messages[state]}
        <ContentWrapper>{form}</ContentWrapper>
      </>
    )
  }

  if (state === 'email') {
    form = (
      <EmailForm
        onCompleted={({ email }) => {
          setEmail(email)
          setState('waitLink')
        }}
      />
    )

    return render[state] ? (
      render[state](form)
    ) : (
      <>
        {messages[state]}
        <ContentWrapper>{form}</ContentWrapper>
      </>
    )
  }

  if (state === 'waitLink') {
    form = (
      <Form
        onSubmit={async () => {
          await auth.handleLoginWithPasswordlessToken({
            email,
            token: tokenField.value,
          })
          setEmail(email)
          handleAuthenticated()
        }}
      >
        <Control label="Enter your 6-digit code">
          <FormField wrapper={Field} field={tokenField} />
        </Control>
        <FormSubmit>
          <Button alignSelf="flex-start" variant="solid" bg="contentAlt">
            Confirm My Email ➔
          </Button>
        </FormSubmit>
      </Form>
    )

    return render[state] ? (
      render[state](form)
    ) : (
      <>
        {messages[state]}
        <ContentWrapper>{form}</ContentWrapper>
      </>
    )
  }
}

export const LoginMethodButton: FC<
  BoxProps & { variant: 'facebook' | 'email' }
> = ({ variant, onClick, children, ...props }) => {
  const color = () => {
    if (variant === 'email') {
      return '#FF6152'
    }
    if (variant === 'facebook') {
      return '#3B5998'
    }
  }

  const icon = () => {
    if (variant === 'email') {
      return <EmailIcon width="60" height="36" color={color()} />
    }
    if (variant === 'facebook') {
      return <FacebookIcon width="60" height="48" color={color()} />
    }
  }

  return (
    <Columns alignItems="center" spacing={2} maxWidth="25em" {...props}>
      {icon()}

      <Button
        type="button"
        paddingHorizontal={3}
        paddingVertical={2}
        bg={color()}
        color="white"
        onClick={onClick}
        variant="solid"
        flex={1}
      >
        <Text align="start" color="white">
          {children}
        </Text>
      </Button>
    </Columns>
  )
}

const EmailField = styled(Field)`
  width: 100%;
`

const EmailForm: FC<{
  onCompleted: ({ email }) => void
}> = ({ onCompleted }) => {
  const auth = useStore(AuthStore)
  const {
    fields: { email },
  } = useFormSchema({
    schema: object({
      email: string().email().required(),
    }),
  })
  const form = useForm({
    fields: [email],
    onSubmit: async () => {
      await auth.loginWithEmail({
        email: email.value,
      })

      onCompleted({ email: email.value })
    },
  })

  return (
    <Form {...form.props}>
      <Control label="Your email address">
        <FormField
          placeholder="Eg: me@example.com"
          wrapper={EmailField}
          field={email}
        />
      </Control>

      <FormSubmit>
        <Button
          alignSelf="flex-start"
          bg="contentAlt"
          css={{
            width: '100%',
            backgroundColor: '#00FF80',
            fontSize: '32px',
            fontWeight: 'bold',
            border: 'none',
            padding: '10px 24px',
            position: 'relative',
            marginTop: '1rem',
          }}
        >
          <span>Send my code</span>
          <span css={{ position: 'absolute', right: '20px' }}>&gt;</span>
        </Button>
      </FormSubmit>
    </Form>
  )
}

type Fn = (...args: unknown[]) => unknown

export type AuthFlow = ComponentType<{ onCompleted: () => void }>

export const useAuthGuard = (AuthFlow: AuthFlow = LoginScreen) => {
  const auth = useStore(AuthStore)
  const [pendingLogin, setPendingLogin] = useState<{
    onCompleted: () => void
  }>()

  const loginView = (
    <Modal open={!!pendingLogin} onClose={() => setPendingLogin(undefined)}>
      <Box width={{ desktop: '50em' }} maxWidth="100vw">
        <AuthFlow
          onCompleted={() => {
            pendingLogin?.onCompleted()
            setPendingLogin(undefined)
          }}
        />
      </Box>
    </Modal>
  )

  function authGuard<T extends Fn>(fn: T): Fn
  function authGuard(fn: Fn): Fn {
    return (...args: unknown[]) => {
      if (!auth.registered) {
        setPendingLogin({
          onCompleted: () => fn(...args),
        })
      } else {
        fn(...args)
      }
    }
  }

  return tuple(loginView, authGuard)
}
