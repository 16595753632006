/** @jsx jsx */

import {
  ColorContext,
  Gutter,
  Backlay,
  BoxProps,
  Box,
  Rows,
  overrideable,
} from '../../design-system'
import { jsx } from '@emotion/core'
import { PinkLine, GreenLine } from '../atoms'
import React from 'react'
import { themeColor } from '../theme'

export interface LayoutCardProps extends BoxProps {
  gutter?: boolean
  lines?: boolean
  lineCount?: number
  lineOffsetX?: number
  lineOffsetY?: number
  padded?: boolean
}

export const LayoutCard = overrideable(
  ({
    children,
    lines,
    padding = { mobile: 2, desktop: 4 },
    paddingVertical,
    paddingHorizontal,
    gutter = true,
    lineCount = 5,
    lineOffsetX = 0,
    lineOffsetY = 0,
    ...props
  }: LayoutCardProps) => {
    const content = (
      <Rows {...{ padding, paddingVertical, paddingHorizontal }}>
        <ColorContext.Provider
          value={{
            bg: typeof props.bg === 'string' ? props.bg : themeColor.bg,
          }}
        >
          {children}
        </ColorContext.Provider>
      </Rows>
    )

    return (
      <Box overflow="visible" maxWidth="100%" provide {...props}>
        <Backlay>
          {lines && (
            <div>
              {lineCount > 1 && (
                <GreenLine top={lineOffsetY} right={lineOffsetX} />
              )}
              {lineCount > 2 && (
                <PinkLine bottom={lineOffsetY} left={-lineOffsetX} />
              )}
              {lineCount > 3 && (
                <GreenLine top={lineOffsetY} left={-lineOffsetX} />
              )}
              {lineCount > 4 && (
                <PinkLine top={lineOffsetY} right={lineOffsetX + 2} />
              )}
              <PinkLine bottom={lineOffsetY} right={lineOffsetX} />
            </div>
          )}
          {gutter ? <Gutter>{content}</Gutter> : content}
        </Backlay>
      </Box>
    )
  },
)
