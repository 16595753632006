/** @jsx jsx */
import { FC, Children, Fragment } from 'react'
import { jsx } from '@emotion/core'
import {
  BoxLink,
  Rows,
  Text,
  Flex,
  BoxProps,
  Heading,
  fullBleed,
  Box,
  Gutter,
  Columns,
  AugmentTheme,
  Content,
  styled,
  spacingLevel,
  makeResponsive,
  responsive,
  VideoEmbed,
  ImageView,
} from '../../design-system'
import React from 'react'
import {
  Assemblage1Desktop,
  Assemblage2Desktop,
  Assemblage1Mobile,
  Assemblage2Mobile1,
  Assemblage2Mobile2,
  Assemblage2DesktopAug,
} from '../graphics/assemblages'
import { ProtectedContent } from '../../twt-core'

interface TeaserBlockProps extends BoxProps {
  heading: string
  linkUrl: string
  imageUrl: string
}

const TeaserHeading = styled('span')`
  ${({ theme }) => theme.font('heading')}
  p, h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
  }
  a {
    color: ${({ theme }) => theme.color('link')};
  }
`

/** Because padding doesn't work when you're masking an image background */
const TeaserCard = styled(Rows)<{ cardColor: string }>(
  ({ theme, cardColor }) => ({
    borderColor: theme.color(cardColor),
    borderStyle: 'solid',
    ...responsive({
      mobile: { borderWidth: spacingLevel(2) },
      desktop: { borderWidth: spacingLevel(3) },
    }),
  }),
)

export const TeaserBlock: FC<TeaserBlockProps> = ({
  heading,
  linkUrl,
  imageUrl,
  children,
  ...props
}) => {
  const [subheading, rest1, ...rest] = Children.toArray(children)

  return (
    <Fragment>
      <TeaserCard
        showFrom="desktop"
        cardColor="tomato"
        bg="tomato"
        height="100vh"
        minHeight="50vw"
      >
        <Box height="112px" minHeight="112px" flexShrink={0} />

        <Columns height="100%">
          <Box paddingBottom={2} flex={1} height="100%" width="50%" bg="tomato">
            <Rows flex={1} width="100%" height="100%" spacing={2} z={2}>
              <Heading level={1} variant="display">
                {heading}
              </Heading>

              <AugmentTheme
                overrides={[{ component: Content, use: TeaserHeading }]}
              >
                {subheading}
              </AugmentTheme>

              <Flex />

              <Assemblage1Desktop
                maxWidth="100%"
                height="250px"
                width="250px"
              />
            </Rows>
          </Box>

          <ImageView
            maxWidth="50%"
            src={require('../assets/images/assem1.gif')}
            height="100%"
            width="auto"
            objectFit="contain"
            style={{ objectPosition: '50% 100%' }}
          />
        </Columns>
      </TeaserCard>

      <TeaserCard
        showUntil="desktop"
        cardColor="tomato"
        minHeight="100vh"
        bg={{
          url: require('../assets/images/Burst1Mob.jpg'),
          objectFit: 'cover',
        }}
      >
        <Box height="64px" bg="tomato" />

        <Box bg="tomato" flex={1} />

        <Box paddingVertical={2} bg="tomato">
          <Heading level={1} variant="display">
            {heading}
          </Heading>
        </Box>

        <Box bg="tomato" flex={1} />

        <Assemblage1Mobile
          width="100%"
          height="auto"
          preserveAspectRatio="xMaxYMax slice"
        />

        <Box bg="tomato" flex={1} />

        <Box bg="tomato" paddingVertical={2}>
          <AugmentTheme
            overrides={[{ component: Content, use: TeaserHeading }]}
          >
            {subheading}
          </AugmentTheme>
        </Box>

        <Box bg="tomato" flex={1} />
      </TeaserCard>

      {rest1 && (
        <TeaserCard
          showFrom="desktop"
          cardColor="cyan"
          bg={{ url: require('../assets/images/Burst2.jpg') }}
          height="100vh"
          minHeight="56vw"
        >
          <Rows flex={1} width="50%" spacing={2} z={2}>
            <Flex />
            <AugmentTheme
              overrides={[{ component: Content, use: TeaserHeading }]}
            >
              {rest1}
              {rest}
            </AugmentTheme>
          </Rows>
          <Rows position="absolute" width="100%" height="100%">
            <Box bg="cyan" flex={1} />

            <Assemblage2Desktop
              width="100%"
              height="auto"
              preserveAspectRatio="xMaxYMax slice"
            />

            <Box bg="cyan" flex={1} />
          </Rows>
        </TeaserCard>
      )}

      {rest1 && (
        <TeaserCard
          showUntil="desktop"
          cardColor="cyan"
          bg={{ url: require('../assets/images/Burst2Mob.jpg') }}
          minHeight="100vh"
        >
          <Box bg="cyan" paddingBottom={2} flex={1} />

          <Assemblage2Mobile1
            width="100%"
            height="auto"
            preserveAspectRatio="xMaxYMax slice"
          />

          <Box bg="cyan" paddingVertical={2}>
            <AugmentTheme
              overrides={[{ component: Content, use: TeaserHeading }]}
            >
              {rest1}
            </AugmentTheme>
          </Box>

          <Assemblage2Mobile2
            width="100%"
            height="auto"
            preserveAspectRatio="xMaxYMax slice"
          />

          <Box bg="cyan" paddingVertical={2}>
            <AugmentTheme
              overrides={[{ component: Content, use: TeaserHeading }]}
            >
              {rest}
            </AugmentTheme>
          </Box>

          <Box bg="cyan" flex={1} />
        </TeaserCard>
      )}
    </Fragment>
  )
}

export const Teaser2: FC<TeaserBlockProps> = ({ heading, children }) => {
  return (
    <Fragment>
      <TeaserCard
        showFrom="desktop"
        cardColor="cyan"
        bg="cyan"
        height="100vh"
        minHeight="56vw"
      >
        <Columns spacing={3}>
          <Rows
            alignItems="flex-start"
            marginRight={2}
            flex={1}
            width="50%"
            spacing={2}
            z={2}
          >
            <Heading level={2} variant="display">
              {heading}
            </Heading>

            {children}

            <Flex />

            <ImageView
              src={require('../assets/images/assem3.gif')}
              height="auto"
              width="auto"
              objectFit="contain"
              style={{ objectPosition: '0% 0%' }}
            />
          </Rows>

          <ImageView
            maxWidth="50%"
            src={require('../assets/images/assem2.gif')}
            height="100%"
            width="auto"
            objectFit="contain"
            style={{ objectPosition: '50% 100%' }}
          />
        </Columns>
      </TeaserCard>

      <TeaserCard
        showUntil="desktop"
        cardColor="cyan"
        bg="cyan"
        minHeight="100vh"
      >
        <Box bg="cyan" paddingBottom={2} flex={1} />

        <Rows alignItems="flex-start" flex={1} spacing={2} z={2}>
          <Heading level={2} variant="display">
            {heading}
          </Heading>

          {children}
        </Rows>

        <Assemblage2Mobile2
          marginTop={3}
          width="100%"
          height="auto"
          preserveAspectRatio="xMaxYMax slice"
        />
      </TeaserCard>

      <Box width="100%" bg="black">
        <VideoEmbed
          height="calc(100vw * 0.5625)"
          maxHeight="100vh"
          video="Zx-64sdSG8c"
        />
      </Box>
    </Fragment>
  )
}
