import React, { FC, ReactNode, Fragment } from "react";
import { Panel, Heading, Columns, overrideable } from "../../design-system";
import { title } from "process";

interface FeatureBlockProps {
  title?: string
  content: ReactNode
  contextualContent: ReactNode
  style?: string
  index?: number
}

export const FeatureCard= overrideable<FeatureBlockProps>(({ content, contextualContent, title, style, index = 0, ...props }) => {
  const contentJsx = (
    <Panel flex={1} spacing={3}>
      {title && <Heading>{title}</Heading>}
      {content}
    </Panel>
  )

  const contextualContentJsx = (
    <Panel maxWidth="50%">
      {contextualContent}
    </Panel>
  )

  const getContent = () => {
    if (index % 2 === 0) {
      return (
        <Fragment>
          {contextualContentJsx}
          {contentJsx}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {contentJsx}
          {contextualContentJsx}
        </Fragment>
      )
    }
  }

  return (
    <Columns flip={{ mobile: true, tablet: false }} justifyContent="center">
      {getContent()}
    </Columns>
  )
})
