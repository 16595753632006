import { FC, Fragment, useState } from 'react'
import React from 'react'
import Modal from 'react-responsive-modal'

import { Box, BoxProps } from '../../design-system'

interface WindowOnMobileProps extends BoxProps {
  open: boolean
  setOpen: (x: boolean) => void
}

export const WindowOnMobile: FC<WindowOnMobileProps> = ({
  open,
  setOpen,
  showFrom = 'desktop',
  ...props
}) => {
  return (
    <Fragment>
      <Box {...props} showFrom={showFrom} />

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box marginTop={2} showUntil={showFrom} {...props} />
      </Modal>
    </Fragment>
  )
}

export const useWindowOnMobile = () => {
  const [state, setState] = useState(false)
  return {
    open: state,
    setOpen: setState,
    openWindow: () => setState(true),
  }
}
