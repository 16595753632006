/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { screens, Theme, spacingLevel } from '../design-system'
import { NeueHaas } from '../assets/fonts'
import { TroisMille } from './assets/fonts'
import { LayoutCard, FeatureCard, Spotlight } from '../twt-core'
import { FeatureCard2020 } from './organisms/feature-card.2020'
import { SectionWrapper, SectionTitle } from '../twt-core/molecules/section'
import { SectionWrapper2020, SectionTitle2020 } from './molecules/section.2020'
import { ArrowLink, ArrowButton } from '../twt-core/molecules/arrow-link'
import { ArrowLink2020, ArrowButton2020 } from './molecules/arrow-link.2020'
import { PageTitle } from '../twt-core/organisms/page-title'
import { PageTitle2020 } from './organisms/page-title.2020'
import { Spotlight2020 } from './organisms/spotlight.2020'
import { DocumentDownloadBlockElement2020 } from './molecules/blocks.2020'
import { DigitalStalls2020 } from './organisms/digital-stalls-subpage-list.2020'
import { SubpageGrid } from '../twt-core/organisms/subpage-grid'
import { DocumentDownloadBlockElement } from '../cms/universal-blocks';

export const colors = {
  tomato: '#FF604C',
  skyBlue: '#CCFEF4',
  lilac: '#DBCEFF',
  cyan: '#00FBC7',
  twtGreen: '#00FAC8',
  indigo: '#784BFB',
  magenta: '#FF2C63',
  black: '#000000',
  darkGrey: '#8C8C8C',
  grey: '#DDDDDD',
  lightGrey: '#F5F5F5',
}

export const themeColors = {
  ...colors,
  bg: 'white',
  foreground: 'white',
  bgAlt: colors.indigo,
  content: colors.black,
  contentLight: colors.darkGrey,
  contentAlt: colors.tomato,
  link: colors.indigo,
  linkActive: colors.tomato,
  control: colors.black,
}

export const themeFonts = {
  regular: css`
    font-family: NeueHaas;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.03em;

    a {
      text-decoration: underline;
    }
  `,
  small: css`
    font-family: NeueHaas;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
  `,
  control: css`
    font-family: NeueHaas;
    font-weight: 500;
    caption: 16px;
    line-height: 150%;
    text-transform: uppercase;
  `,
  controlSection: css`
    font-family: NeueHaas;
    font-weight: 500;
    font-size: 14.6029px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  `,
  heading: css`
    font-family: TroisMille;
    font-weight: bold;

    font-size: 26px;
    line-height: 90%;

    @media (min-width: ${screens.desktop}px) {
      font-size: 42px;
    }
  `,
  headingSmall: css`
    font-family: TroisMille;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;
  `,
  display: css`
    margin: 0;
    font-family: TroisMille;
    font-style: normal;
    font-weight: bold;
    font-size: 51px;
    line-height: 82.8%;

    @media (min-width: ${screens.tablet}px) {
      font-size: 87px;
      line-height: 84%;
    }
  `,
  link: css`
    .active,
    :active {
      opacity: 0.5;
    }

    &:hover:not(:active):not(.active) {
      opacity: 0.5;
    }
  `,
}

export const fonts2020 = [NeueHaas, TroisMille]

const getTheme2020 = (overrides: Partial<typeof themeColors> = {}) =>
  new Theme({ ...themeColors, ...overrides }, themeFonts)
    .override(LayoutCard, (props) => (
      <LayoutCard.Original
        gutter={false}
        padding={{ mobile: 2, desktop: 3 }}
        {...props}
      />
    ))
    .override(FeatureCard, FeatureCard2020)
    .override(SectionWrapper, SectionWrapper2020)
    .override(ArrowLink, ArrowLink2020)
    .override(ArrowButton, ArrowButton2020)
    .override(PageTitle, PageTitle2020)
    .override(SectionTitle, SectionTitle2020)
    .override(Spotlight, Spotlight2020)
    .override(SubpageGrid, DigitalStalls2020)
    .override(DocumentDownloadBlockElement, DocumentDownloadBlockElement2020)
    .style('button', {
      default: ({ bg, color }) => ({
        border: '2px solid ' + (color || 'black'),
        color: color || 'inherit',
        fontSize: 34,
        backgroundColor: bg || 'rgba(0,0,0,0)',
      }),
      solid: ({ bg }) => ({
        border: 'none',
        color: 'white',
        paddingRight: spacingLevel(3),
        paddingLeft: spacingLevel(3),
      }),
      chip: {
        borderRadius: 1.73844,
        fontWeight: 500,
        fontSize: 14.6029,
        border: `1.73844px solid ${colors.indigo}`,
      },
      filter: {
        padding: `${spacingLevel(1)}px ${spacingLevel(3)}px`,
        border: `2px solid ${colors.indigo}`,
        borderRadius: 4,
        color: colors.indigo,
        backgroundColor: 'rgba(0,0,0,0)',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '100%',
        textTransform: 'uppercase',
        '&:hover': {
          opacity: 0.6,
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      toggleChip: {
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.6,
          backgroundColor: 'rgba(0,0,0,0)',
        },
        padding: spacingLevel(1) * 0.5,
        border: `2px solid ${colors.indigo}`,
        color: colors.indigo,
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: 2,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      toggleChipActive: {
        '&:hover': {
          backgroundColor: colors.indigo,
        },
        padding: spacingLevel(1) * 0.5,
        border: `2px solid ${colors.indigo}`,
        color: 'white',
        backgroundColor: colors.indigo,
        borderRadius: 2,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
    })
    .style('menuDivider', {
      default: `
      margin-left: 1.25em;
      ::before {
        content: none !important;
      }
    `,
    })
    .style('card', {
      event: `
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
        background: #FFFFFF;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      `,
    stall: `
      color: ${colors.indigo};
      border: 2px solid ${colors.indigo};
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
      background: #FFFFFF;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    `,
    })

export const theme2020 = getTheme2020()
export const theme2020List = getTheme2020({
  bg: colors.lightGrey,
})
