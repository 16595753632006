import { styled } from '../../design-system/styled';
import { Link } from '../../design-system/atoms/links';
import { spacingLevel } from '../../design-system';

export const BoxLink = styled(Link)`
  color: ${({ theme }) => theme?.colors?.reverseContent};
  background-color: ${({ theme }) => theme?.colors?.content};
`;

export const ContentWrapper = styled.div`
  padding: ${spacingLevel(2)}px;
`
