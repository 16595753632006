import * as core from '../twt-core/theme'
import css from '@emotion/css'
import { screens } from '../design-system'

export const themeColor = {
  ...core.themeColor,
  link: '#48CBB1',
  contentAlt: '#48CBB1',
}

export const themeFonts = {
  ...core.themeFonts,
  display: css`
    font-weight: 700;
    font-family: 'Goudy', serif;
    line-height: 105.4%;

    text-decoration: underline solid #48cbb1;

    @media (min-width: ${screens.desktop}px) {
      font-size: 58px;
    }
  `,
  heading: css`
    font-family: 'Goudy', serif;
    font-weight: 700;
    line-height: 110%;
    font-size: 31px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 31px;
    }
  `,
  link: css`
    .active,
    :active {
      color: inherit;
    }

    color: inherit;

    :hover:not(:active) {
      text-decoration: underline;
      opacity: 0.7;
    }
  `,
  content: css`
    font-family: 'NeueHaas', sans serif;
    font-size: 25px;
    line-height: 127%;
  `,
  cell: css`
    font-family: 'NeueHaas', sans serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  `,
  cellTitle: css`
    font-family: 'NeueHaas', sans serif;
    font-size: 18px;
    line-height: 27px;
  `,
  gridCell: css`
    font-family: 'NeueHaas', sans serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 127%;
    letter-spacing: -0.03em;
  `,
  buttonTextSmall: css`
    font-family: 'NeueHaas', sans serif;
    font-size: 11px;
    line-height: 127%;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: ${themeColor.link};

    .active,
    :active {
      color: ${themeColor.link};
      font-weight: 500;
      text-decoration: underline;
    }
    :hover:not(:active) {
      color: ${themeColor.linkActive};
    }
  `,
  sidebarLink: css`
    .active,
    :active {
      color: ${themeColor.link};
      font-weight: 500;
      text-decoration: underline;
    }

    font-size: 25px;
    line-height: 127%;
    color: ${themeColor.link};

    :hover:not(:active) {
      color: ${themeColor.linkActive};
      text-decoration: underline;
    }
  `,
}
