import React from 'react'
import { styled, SvgGraphicProps, SvgGraphic } from '../../design-system'

interface ArrowRightProps extends React.SVGProps<SVGSVGElement> {
  fill?: string
}

const ArrowRightContent = ({ fill = '#fff', ...props }: ArrowRightProps) => (
  <svg width={64} height={47} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.641 22.86a4.556 4.556 0 0 1-4.512 4.6l-50.71.481a4.556 4.556 0 0 1-.086-9.111l50.709-.482a4.556 4.556 0 0 1 4.6 4.512z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.784 1.376a4.556 4.556 0 0 1 6.443-.083l22.155 21.59L41.705 45.03a4.556 4.556 0 0 1-6.512-6.373l15.29-15.623L34.867 7.82a4.556 4.556 0 0 1-.083-6.443z"
      fill={fill}
    />
  </svg>
)

export const ArrowSmall = ({ stroke, ...props }: SvgGraphicProps) => (
  <SvgGraphic
    viewBox="0 0 35 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.0670471 9.57336L32.3618 9.57336"
      stroke={stroke || "#001426"}
      strokeWidth="1.5"
    />
    <path d="M34 10.1418L24.8499 0.991699" stroke={stroke || "#001426"} strokeWidth="1.5" />
    <path d="M34 9.16089L24.8515 18.3093" stroke={stroke || "#001426"} strokeWidth="1.5" />
  </SvgGraphic>
)

export const ArrowRight = styled(ArrowRightContent)`
  transform: translateY(-2px);
`
