import React, { createContext, useContext, FC } from 'react'
import { graphql } from 'gatsby'
import { Subpage } from './__generated__/Subpage'

export interface CmsPageContext {
  pageType: string
  subpages: Subpage[]
  theme: string
  title: string
  fullPage?: boolean
}

export const ArticlePageType = 'article_page'
export const ListPageType = 'list_page'
export const HomePageType = 'home_page'
export const CategoryPageType = 'category'

export const CoreTheme = 'core_brand'
export const DigitalHubTheme = 'hub_brand'
export const TWT2020Theme = 'twt20_brand'
export const TWT2021Theme = 'twt21_brand'

export const subpageFragment = graphql`
  fragment Subpage on WAGTAIL_HomePage {
    title
    url
    postcode
    image {
      src
    }
  }
`

export const CmsPageContext = createContext<CmsPageContext | undefined>(
  undefined,
)
export const useCmsPageContext = () => {
  const ctx = useContext(CmsPageContext)
  if (!ctx) {
    throw Error('Missing Cms context')
  }

  return ctx
}

export const FullPage: FC = ({ children }) => {
  const context = useContext(CmsPageContext)

  return (
    <CmsPageContext.Provider value={{ ...context, fullPage: true }}>
      {children}
    </CmsPageContext.Provider>
  )
}
