import { graphql } from 'gatsby'
import { FC } from 'react'
import { getStructField, getStructFieldOptional } from './cms.util'
import { themeColor } from '../twt-core/theme'
import React from 'react'
import { LayoutCard, LayoutCardProps } from '../twt-core/molecules'
import { SectionBase } from './__generated__/SectionBase'
import { SectionTitle } from './__generated__/SectionTitle'
import { SectionStyle } from './__generated__/SectionStyle'
import { SectionDecorated } from './__generated__/SectionDecorated'
import {
  useCmsPageContext,
  ArticlePageType,
  DigitalHubTheme,
  TWT2020Theme,
} from './context'
import { Gutter } from '../design-system'
import { SectionWrapper } from '../twt-core/molecules/section'

export const sectionFragment = graphql`
  fragment SectionBase on WAGTAIL_StructBlock {
    blocks {
      field
      ...SectionTitle
      ...SectionDecorated
      ...SectionStyle
    }
  }

  fragment SectionTitle on WAGTAIL_TextBlock {
    title: value
  }

  fragment SectionStyle on WAGTAIL_ChoiceBlock {
    style: value
  }

  fragment SectionDecorated on WAGTAIL_BooleanBlock {
    decorated: value
  }
`

export const getSectionProps = (block: SectionBase) => {
  const styleField = getStructFieldOptional<SectionStyle>(block, 'style')

  return {
    title: getStructField<SectionTitle>(block, 'title').title,
    style: styleField ? styleField.style : 'normal',
    decorated: getStructField<SectionDecorated>(block, 'decorated').decorated,
  }
}

export const SectionElement: FC<{
  block: SectionBase
  index?: number
  context: string
}> = ({ block, index, children, context }) => {
  const { decorated, title, style } = getSectionProps(block)

  return (
    <SectionWrapper
      decorated={decorated}
      title={title}
      style={style}
      index={index}
      context={context}
    >
      {children}
    </SectionWrapper>
  )
}
